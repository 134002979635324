<template>
    <!-- 订单列表 -->
     <!-- style="height:100%;background:#fff;padding-top:0.6rem;" -->
    <div style="height:calc(100% - 0.6rem);background:#fff;padding-top:0.6rem;">
        <div class="autoFucus" style="position:fixed;top:0;left:0;z-index: 888;">
            <nut-cell :show-icon="true" title="" :desc="searchform.typeName" custom-class-name='typeClass' @click.native="openSwitchType('typeIsVisible')"></nut-cell>
            <nut-picker :is-visible="typeIsVisible" :list-data="typeoptions" title="搜索类型" @close="closeSwitchType('typeIsVisible')" @confirm="confirmType"></nut-picker>
            <nut-textinput  class="my-input" type="search" v-model="searchform.searchname" :placeholder="placeholder" ref="myInput" @keyup.enter="submit()" >
                <template v-slot:clearBtn>
                    <nut-icon type="cross"></nut-icon>
                </template>
            </nut-textinput>
            <div class="searchBtn" @click="submit()">搜索</div>
        </div>

        <!-- style="height: calc(100% - 1.3rem);" -->
        <nut-scroller
            :is-un-more="isUnMore" 
            :is-loading="isLoading"
            :type="'vertical'"
            :scrollTo="scrollTo"
            @loadMore="loadMoreVert"
            @pulldown="pulldown"
            @scrollChange="scrollChange"
            :loadingTxt="'加载中...'"
        > 
            <div slot="list" class="nut-vert-list-panel">
                <div class="nut-vert-list-item" v-for="(item, index) of orderList" @click="getOrderDetail(item)" :key="index">
                    <div class="flexDiv">
                        <p style="flex: 2;">{{item.oid}}</p>
                        <p>{{item.createTime}}</p>
                    </div>
                    <div class="flexDiv">
                        <p style="flex: 2;">
                            <span @click.stop="showName(item)">
                                {{item.customer_nameCopy}}
                                <i :class="!item.customer_nameChange?'iconfont icon-biyanjing':'iconfont icon-yanjing'" style="color:#2bc58f;margin-left:0.06rem;"></i>
                            </span>
                        </p>
                        <p>
                            <span @click.stop="showPhone(item)">
                                {{item.mobileCopy}}
                                <i :class="!item.mobileChange?'iconfont icon-biyanjing':'iconfont icon-yanjing'" style="color:#2bc58f;margin-left:0.06rem;"></i>
                            </span>
                        </p>
                    </div>
                    <div v-if="item.itemType==1">
                        <div class="flexDiv" v-for="(goods,goodsInd) in item.order_goods" :key="goodsInd">
                            <p style="flex: 2;">{{goods.goods_name}}</p>
                            <p>{{goods.price}}</p>
                        </div>
                    </div>
                    <div v-if="item.itemType==2">
                        <div class="flexDiv" v-for="(goods,goodsInd) in item.order_goods" :key="goodsInd">
                            <p style="flex: 2;">{{goods.package_name}}</p>
                            <p>{{goods.package_price}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </nut-scroller>
    </div>
</template>
<script>
import { mapState } from "vuex";
import { getMyOrder,customerList,customerView } from '@/api/api.js'
export default {
    components: {
    },
    data(){
        return {
            typeIsVisible: false,
            typeoptions: [
                [{label:'1',value:'名称/手机号',},
                {label:'2',value:'订单号',}]
            ],
            placeholder:'请输入客户名称/手机号',
            orderList:[],
            searchform:{
                type:'1',
                typeName: '名称/手机号',
                searchname:'',
                customer_name:'',
                mobile:'',
                order_sn:'',
                page: 1,
                pageNum:10,
            },
            maxPages: 4,
            isUnMore: false,
            isLoading: false,
            timer: null,
            scrollTo:-100,
            loadingport:false,
        }
    },
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {
        console.log(this.userInfo.roleIds.indexOf(4) != -1,this.userInfo.roleIds.indexOf(6) != -1)
        if(this.userInfo.roleIds.indexOf(4) != -1||this.userInfo.roleIds.indexOf(6) != -1){
            this.searchform.type='2';
            this.searchform.typeName='订单号';
            this.placeholder='请输入订单号';
        }
        this.getorderList();
    },
    computed: {
      ...mapState(['userInfo']),
    },
    // 生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {},
    // 监控data中的数据变化
    watch: {},
    // 方法集合
    methods: {
        openSwitchType(param) {
            this[`${param}`] = true;
        },
        closeSwitchType(param) {
            this[`${param}`] = false;
        },
        confirmType(chooseData) {
            this.scrollTo=0;
            this.searchform.typeName = `${chooseData[0].value}`;
            this.searchform.type = `${chooseData[0].label}`;
            if(this.searchform.type==1){
                this.placeholder='请输入客户名称/手机号';
            }else{
                this.placeholder='请输入订单号';
            }
            this.searchform.searchname='';
            this.submit();
        },
        submit(){
            this.scrollTo=0;
            this.searchform.page=1;
            this.searchform.pageNum=10;
            if(this.searchform.searchname!=''){
                if(this.searchform.type==1){
                    if(/^1\d{10}$/.test(this.searchform.searchname)){
                        this.searchform.mobile=this.searchform.searchname;
                    }else{
                        this.searchform.customer_name=this.searchform.searchname;
                    }
                    this.searchform.order_sn='';
                }else{
                    this.searchform.order_sn=this.searchform.searchname;
                    this.searchform.mobile='';
                    this.searchform.customer_name='';
                }
            }else{
                this.searchform.mobile='';
                this.searchform.customer_name='';
                this.searchform.order_sn='';
            }
            this.orderList = [];
            this.getorderList();
        },
        scrollChange(value){
            this.scrollTo=value;
        },
        loadMoreVert() {
            this.isLoading = true;
            if (this.searchform.page > this.maxPages) {
                this.isUnMore = true;
                this.isLoading = false;
            } else {
                // clearTimeout(this.timer);
                // this.timer = setTimeout(() => {
                //     this.isLoading = false;
                //     this.isUnMore = false;
                //     this.orderList = new Array(20 * this.searchform.page);
                //     this.searchform.page = ++this.searchform.page;
                // }, 300);
                
                this.searchform.page = ++this.searchform.page;
                this.getorderList();
            }
        },
        pulldown() {
            this.orderList = [];
            this.isLoading = true;
            // clearTimeout(this.timer);
            // this.timer = setTimeout(() => {
            //     this.isLoading = false;
            //     this.isUnMore = false;
            //     this.orderList = new Array(20);
            //     this.searchform.page = 2;
            // }, 3000);
            this.searchform.page = 1;
            this.getorderList();
        },
        getorderList(){
            let parmas = this.searchform;
            getMyOrder(parmas).then(res => {
                if(res.data.code==200){
                    res.data.data.data.forEach(item => {
                        item.customer_nameCopy=item.desensitise_customer_name;
                        item.customer_nameChange=false;
                        item.mobileCopy=item.desensitise_mobile;
                        item.mobileChange=false;
                    });
                    this.isLoading = false;
                    this.isUnMore = false;
                    this.orderList=[...this.orderList, ...res.data.data.data];
                    this.maxPages=Math.ceil(res.data.data.total/20)
                }else{
                    this.$toast.fail(res.data.msg,{duration: 2000});
                }
            });
        },
        callPhone(phoneNumber) {
            console.log(phoneNumber);
            window.location.href = "tel://" + phoneNumber;
        },
        getOrderDetail(data){
            this.$router.push({
                path:'/orderDetails',
                query:{
                    id:data.oid
                }
            })
        },

        //客户信息解密
        async showName(data){
            if(!data.customer_nameChange){
                let returnData=await this.getcustomerView(data.customer_id,1,2,data.oid);
                data.customer_nameCopy=returnData;
                data.customer_nameChange=returnData?true:false;
            }else{
                data.customer_nameCopy=data.desensitise_customer_name;
                data.customer_nameChange=false;
            }
        },
        async showPhone(data){
            if(!data.mobileChange){
                let returnData=await this.getcustomerView(data.customer_id,2,2,data.oid);
                data.mobileCopy=returnData;
                data.mobileChange=returnData?true:false;
            }else{
                data.mobileCopy=data.desensitise_mobile;
                data.mobileChange=false;
            }
        },
        copyText(phone){
            let that=this;
            this.$copyText(phone).then(function(){
                that.$toast.success("内容已复制到剪切板!",{duration: 2000});
            }, function(e) {

            });
        },
        async getcustomerView(customer_id,type,source,oid){
            if (this.loadingport) {
                return
            }
            this.loadingport=true;
            let decryptData='';
            let parmas = {customer_id,type,source,oid,};
            await customerView(parmas).then(res => {
                if(res.data.code==200){
                    decryptData=res.data.data.decryptData;
                    this.loadingport=false;
                }else{
                    this.$toast.fail(res.data.msg,{duration: 2000});
                    this.loadingport=false;
                }
            });
            return decryptData
        },
        //客户信息解密end
    }
}
</script>
<style scoped>
.autoFucus {
    background: #fff;
    padding: 0.1rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width:calc(100% - 20px);
    /* margin:0 auto; */
}
.autoFucus .my-input {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1
}
.autoFucus .searchBtn {
    margin-left: 10px
}
.searchBtn{
    font-size: 14px;
}
.nut-vert-list-item{
    font-size: 14px;
    padding:0.1rem 0.2rem;
}

.nut-vert-list-item:nth-child(2n){
    background: #fafafa;
}
.flexDiv{
    display: flex;
    margin-bottom: 6px;
}
</style>
<style>
    .autoFucus .nut-textinput input{
        height: 0.4rem;
    }
</style>
