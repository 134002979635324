import Vue from 'vue'
import Router from 'vue-router'
import login from "@/views/login"
import home from "@/views/home"
import customerList from "@/views/customerList"
import customerDetails from "@/views/customerDetails"
import orderList from "@/views/orderList"
import giftorderList from "@/views/giftorderList"
import orderDetails from "@/views/orderDetails"
import createdOrder from "@/views/createdOrder"
import giftorderDetails from "@/views/giftorderDetails"

Vue.use(Router)
 
export default new Router({
    routes: [
        {
            path: "/",
            component: login
        },
        {
            path: "/login",
            component: login
        },
        {
            path: "/home",
            component: home,
            redirect: '/customerList',
            children:[
                {path: "/customerList",component: customerList},
                {path: "/orderList",component: orderList},
                {path: "/giftorderList",component: giftorderList},
            ]
        },
        {
            path: "/customerDetails",
            component: customerDetails
        },
        {
            path: "/createdOrder",
            component: createdOrder
        },
        {
            path: "/orderDetails",
            component: orderDetails
        },
        {
            path: "/giftorderDetails",
            component: giftorderDetails
        },
        
    ]
})