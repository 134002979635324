<template>
    <div style="height:100%;background:#fff;">
        <nut-navbar 
            style="background-color:#545456; color: #fff;"
            @on-click-back="navback" 
            :leftShow="true" 
            :rightShow="false"
        >订单信息</nut-navbar>
        <!-- <nut-buttongroup>
            <nut-button @click="callPhone()" type="light">打电话</nut-button>
            <nut-button @click="getCreatedOrder()">下单</nut-button>
        </nut-buttongroup> -->

        <nut-collapse v-model="activeNames" @change="callback">
            <nut-collapse-item title="客户信息" :name="1">
                <div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">客户名称</p>
                        <p @click="showName()" style="flex: 2;text-align:right;">
                            {{customer_nameCopy}}
                            <i :class="!customer_nameChange?'iconfont icon-biyanjing':'iconfont icon-yanjing'" style="color:#2bc58f;margin-left:0.06rem;"></i>
                        </p>
                    </div>
                    <!-- <div class="flexDiv">
                        <p style="color:#2d2d2d;">电话</p>
                        <p style="flex: 2;text-align:right;">{{orderDel.mobile}}</p>
                    </div> -->
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">年龄段</p>
                        <p style="flex: 2;text-align:right;">{{orderDel.customer_age}}</p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">客户症状</p>
                        <p style="flex: 2;text-align:right;">{{orderDel.customer_disease_remark}}</p>
                    </div>
                </div>
            </nut-collapse-item>
            <nut-collapse-item title="订单信息" :name="2">
                <div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">订单号</p>
                        <p style="flex: 2;text-align:right;">{{orderDel.oid}}</p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">订单状态</p>
                        <p style="flex: 2;text-align:right;">{{orderDel.status_detail}}</p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">订单类型</p>
                        <p style="flex: 2;text-align:right;">{{orderDel.purchase}}</p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">下单时间</p>
                        <p style="flex: 2;text-align:right;">{{orderDel.createTime}}</p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">支付时间</p>
                        <p style="flex: 2;text-align:right;">{{orderDel.payTime}}</p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">销售姓名</p>
                        <p style="flex: 2;text-align:right;">{{orderDel.admin_name}}</p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">销售备注</p>
                        <p style="flex: 2;text-align:right;">{{orderDel.admin_remark}}</p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">订单金额</p>
                        <p style="flex: 2;text-align:right;">{{orderDel.totalFee}}</p>
                    </div>
                </div>
            </nut-collapse-item>
            <nut-collapse-item v-if="orderGoodsInfo.length" title="商品信息" :name="3">
                <div>
                    <div v-show="item.item_type==1" v-for="(item,index) in orderGoodsInfo" :key="index">
                        <div class="flexDiv">
                            <p style="color:#2d2d2d;">商品类型</p>
                            <p style="flex: 2;text-align:right;">商品</p>
                        </div>
                        <div class="flexDiv">
                            <p style="color:#2d2d2d;">商品名称</p>
                            <p style="flex: 2;text-align:right;">{{item.goods_name}}</p>
                        </div>
                        <div class="flexDiv">
                            <p style="color:#2d2d2d;">商品规格</p>
                            <p style="flex: 2;text-align:right;">{{item.goods_attr}}</p>
                        </div>
                        <div class="flexDiv">
                            <p style="color:#2d2d2d;">数量</p>
                            <p style="flex: 2;text-align:right;">{{item.num}}</p>
                        </div>
                        <div class="flexDiv">
                            <p style="color:#2d2d2d;">价格</p>
                            <p style="flex: 2;text-align:right;">{{item.price}}</p>
                        </div>
                    </div>
                </div>
                <div>
                    <div v-show="item.item_type==2" v-for="(item,index) in orderGoodsInfo" :key="index">
                        <div class="flexDiv">
                            <p style="color:#2d2d2d;">商品类型</p>
                            <p style="flex: 2;text-align:right;">套餐</p>
                        </div>
                        <div class="flexDiv">
                            <p style="color:#2d2d2d;">套餐名称</p>
                            <p style="flex: 2;text-align:right;">{{item.package_name}}</p>
                        </div>
                        <div class="flexDiv">
                            <p style="color:#2d2d2d;">套餐详情</p>
                            <div style="flex: 2;text-align:right;">
                                <div v-for="(goods,goodindex) in item.goods_detail" :key="goodindex">
                                    <p>商品:{{goods.goods_name}}</p>
                                    <p>规格：{{goods.goods_attr}}</p>
                                    <p>数量：{{goods.num}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="flexDiv">
                            <p style="color:#2d2d2d;">数量</p>
                            <p style="flex: 2;text-align:right;">{{item.package_quantity}}</p>
                        </div>
                        <div class="flexDiv">
                            <p style="color:#2d2d2d;">价格</p>
                            <p style="flex: 2;text-align:right;">{{item.package_price}}</p>
                        </div>
                    </div>
                </div>
            </nut-collapse-item>
            <nut-collapse-item v-if="ordergiftGoods.length" title="赠品信息" :name="4">
                <div>
                    <div v-show="item.item_type==1" v-for="(item,index) in ordergiftGoods" :key="index" :style="ordergiftGoods.length!=(index+1)?'border-bottom:1px dashed #ccc;':''">
                        <!-- <div class="flexDiv">
                            <p style="color:#2d2d2d;">商品类型</p>
                            <p style="flex: 2;text-align:right;">商品</p>
                        </div> -->
                        <div class="flexDiv">
                            <p style="color:#2d2d2d;">商品名称</p>
                            <p style="flex: 2;text-align:right;">{{item.goods_name}}</p>
                        </div>
                        <div class="flexDiv">
                            <p style="color:#2d2d2d;">商品规格</p>
                            <p style="flex: 2;text-align:right;">{{item.goods_attr}}</p>
                        </div>
                        <div class="flexDiv">
                            <p style="color:#2d2d2d;">数量</p>
                            <p style="flex: 2;text-align:right;">{{item.num}}</p>
                        </div>
                        <div class="flexDiv">
                            <p style="color:#2d2d2d;">价格</p>
                            <p style="flex: 2;text-align:right;">{{item.price}}</p>
                        </div>
                    </div>
                </div>
                <div>
                    <div v-show="item.item_type==2" v-for="(item,index) in ordergiftGoods" :key="index">
                        <!-- <div class="flexDiv">
                            <p style="color:#2d2d2d;">商品类型</p>
                            <p style="flex: 2;text-align:right;">套餐</p>
                        </div> -->
                        <div class="flexDiv">
                            <p style="color:#2d2d2d;">套餐名称</p>
                            <p style="flex: 2;text-align:right;">{{item.package_name}}</p>
                        </div>
                        <div class="flexDiv">
                            <p style="color:#2d2d2d;">套餐详情</p>
                            <div style="flex: 2;text-align:right;">
                                <div v-for="(goods,goodindex) in item.goods_detail" :key="goodindex">
                                    <p>商品:{{goods.goods_name}}</p>
                                    <p>规格：{{goods.goods_attr}}</p>
                                    <p>数量：{{goods.num}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="flexDiv">
                            <p style="color:#2d2d2d;">数量</p>
                            <p style="flex: 2;text-align:right;">{{item.package_quantity}}</p>
                        </div>
                        <div class="flexDiv">
                            <p style="color:#2d2d2d;">价格</p>
                            <p style="flex: 2;text-align:right;">{{item.package_price}}</p>
                        </div>
                    </div>
                </div>
            </nut-collapse-item>
            <nut-collapse-item title="物流信息" :name="5">
                <div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">收货人</p>
                        <p @click="showConsignee()" style="flex: 2;text-align:right;">
                            {{consigneeCopy}}
                            <i :class="!consigneeChange?'iconfont icon-biyanjing':'iconfont icon-yanjing'" style="color:#2bc58f;margin-left:0.06rem;"></i>
                        </p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">手机号</p>
                        <p @click="showPhone()" style="flex: 2;text-align:right;">
                            {{mobileCopy}}
                            <i :class="!mobileChange?'iconfont icon-biyanjing':'iconfont icon-yanjing'" style="color:#2bc58f;margin-left:0.06rem;"></i>
                        </p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">地域</p>
                        <p style="flex: 2;text-align:right;">{{orderDel.province}}{{orderDel.city}}</p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">区县</p>
                        <p style="flex: 2;text-align:right;">{{orderDel.district}}</p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">地址</p>
                        <p @click="showStreet()" style="flex: 2;text-align:right;">
                            {{addressCopy}}
                            <i :class="!addressChange?'iconfont icon-biyanjing':'iconfont icon-yanjing'" style="color:#2bc58f;margin-left:0.06rem;"></i>
                        </p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">发货时间</p>
                        <p style="flex: 2;text-align:right;">{{orderDel.sendTime}}</p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">物流类型</p>
                        <p style="flex: 2;text-align:right;">{{orderDel.sendType}}</p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">物流单号</p>
                        <p style="flex: 2;text-align:right;">{{orderDel.sendCode}}</p>
                    </div>
                </div>
            </nut-collapse-item>
            <nut-collapse-item title="审核信息" :name="6">
                <nut-timeline>
                    <nut-timelineitem v-for="(item,index) in work_to_node_operate_list" :key="index" :pointColor="item.status == 1?'#67C23A':'#ccc'">
                        <div slot="title">{{item.handle_date}}</div>
                        <div class="content">
                            {{ item.node_name }}：{{item.name}} &nbsp;&nbsp;&nbsp;进程状态：{{ item.status_text}}
                        </div>
                    </nut-timelineitem>
                </nut-timeline>
                <div v-if="is_node_handle">
                    <div>
                        <nut-radiogroup v-model="auditform.handle_type" direction="horizontal" style="display:inline-block;">
                            <nut-radio label="1">通过</nut-radio>
                            <nut-radio label="2">拒绝</nut-radio>
                        </nut-radiogroup>
                        <nut-textbox v-if="auditform.handle_type==2" v-model="auditform.handle_remark" :place-text="'拒绝原因'"></nut-textbox>
                    </div>
                    <div style="text-align:right;margin-top:10px;">
                        <nut-button @click="resetAudit()" type="light" shape="circle" small style="margin-right:10px;">取消</nut-button>
                        <nut-button @click="submitAudit()" type="red" shape="circle" small>提交</nut-button>
                    </div>
                </div>
            </nut-collapse-item>
        </nut-collapse>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { examineGiftDetails,handleExamineGift,customerView,customerPhoneView } from '@/api/api.js'
import common from '../common/js/util.js';
export default {
    components: {
    },
    data(){
        return {
            orderId:this.$route.query.id,
            customer_nameCopy:'',
            customer_nameChange:false,
            consigneeCopy:'',
            consigneeChange:false,
            mobileCopy:'',
            mobileChange:false,
            addressCopy:'',
            addressChange:false,
            orderDel:{
            },
            orderGoodsInfo:[],
            ordergiftGoods:[],
            work_to_node_operate_list:[],
            activeNames: [1,2,3,4,6],
            loadingport:false,
            auditform:{
                work_id:'',
                handle_type:'',
                handle_remark:'',
            },
            is_node_handle:'',
        }
    },
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {
        this.getexamineGiftDetails();
    },
    computed: {
      ...mapState(['userInfo']),
    },
    // 生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {
        let name = this.$store.state.userInfo && this.$store.state.userInfo.name ? this.$store.state.userInfo.name : '请先登录';
        let phone = this.$store.state.userInfo && this.$store.state.userInfo.phone ? this.$store.state.userInfo.phone : '请先登录';
        this.$watermark.set(name +'-' + common.dateFormat('YYmmdd', new Date()));
    },
    // 监控data中的数据变化
    watch: {},
    // 方法集合
    methods: {
        navback(){
            // this.$router.push({
            //     path:'/orderList'
            // })
            this.$router.back();
        },
        callback(name) {},
        callPhone(phoneNumber) {
            // console.log(this.orderDel.mobile);
            // window.location.href = "tel://" + this.orderDel.mobile;
            this.getcustomerPhoneView(this.orderDel.customer_id,2,3,this.orderDel.oid);
        },
        getCreatedOrder(){
            this.$router.push({
                path:'/createdOrder',
                query:{
                    id:this.orderDel.customer_id
                }
            })
        },
        //订单详情
        getexamineGiftDetails() {
            examineGiftDetails({work_id:this.orderId}).then(res => {
                if(res.data.code==200){
                    console.log(res.data.data)
                    // this.orderDel=res.data.data.orderInfo;
                    this.is_node_handle=res.data.data.is_node_handle;
                    this.orderDel=res.data.data.work_to_order;

                    this.customer_nameCopy=this.orderDel.desensitise_consignee;
                    this.consigneeCopy=this.orderDel.desensitise_consignee;
                    this.mobileCopy=this.orderDel.desensitise_mobile;
                    this.addressCopy=this.orderDel.desensitise_address;

                    this.orderGoodsInfo=this.orderDel.order_to_goods.goods_data;
                    this.ordergiftGoods=this.orderDel.order_to_goods.gift_data;
                    this.work_to_node_operate_list=res.data.data.work_to_node_operate_list;
                }else{
                    this.$toast.fail(res.data.msg,{duration: 2000});
                }
            });
        },
        resetAudit(){
            this.auditform.handle_type='';
            this.auditform.handle_remark='';
        },
        async submitAudit(){
            this.auditform.work_id=this.orderId;
            if(this.auditform.handle_type==2&&this.auditform.handle_remark==''){
                this.$toast.fail('请输入拒绝原因',{duration: 2000});
                return false;
            }
            let parmas = this.auditform;
            await handleExamineGift(parmas).then(res => {
                if(res.data.code==200){
                    this.$toast.success("提交成功",{duration: 2000});
                    this.getexamineGiftDetails();
                }else{
                    this.$toast.fail(res.data.msg,{duration: 2000});
                }
            });
        },
        //客户信息解密
        async showName(data){
            if(!this.customer_nameChange){
                let returnData=await this.getcustomerView(this.orderDel.customer_id,1,3,this.orderDel.oid);
                this.customer_nameCopy=returnData;
                this.customer_nameChange=returnData?true:false;
            }else{
                this.customer_nameCopy=this.orderDel.desensitise_consignee;
                this.customer_nameChange=false;
            }
        },
        async showConsignee(data){
            if(!this.consigneeChange){
                let returnData=await this.getcustomerView(this.orderDel.customer_id,1,2,this.orderDel.oid);
                this.consigneeCopy=returnData;
                this.consigneeChange=returnData?true:false;
            }else{
                this.consigneeCopy=this.orderDel.desensitise_consignee;
                this.consigneeChange=false;
            }
        },
        async showPhone(data){
            if(!this.mobileChange){
                let returnData=await this.getcustomerView(this.orderDel.customer_id,2,2,this.orderDel.oid);
                this.mobileCopy=returnData;
                this.mobileChange=returnData?true:false;
            }else{
                this.mobileCopy=this.orderDel.desensitise_mobile;
                this.mobileChange=false;
            }
        },
        async showStreet(data){
            if(!this.addressChange){
                let returnData=await this.getcustomerView(this.orderDel.customer_id,3,2,this.orderDel.oid);
                this.addressCopy=returnData;
                this.addressChange=returnData?true:false;
            }else{
                this.addressCopy=this.orderDel.desensitise_address;
                this.addressChange=false;
            }
        },
        async getcustomerView(customer_id,type,source,oid){
            if (this.loadingport) {
                return
            }
            this.loadingport=true;
            let decryptData='';
            let parmas = {customer_id,type,source,oid,};
            await customerView(parmas).then(res => {
                if(res.data.code==200){
                    decryptData=res.data.data.decryptData;
                    this.loadingport=false;
                }else{
                    this.$toast.fail(res.data.msg,{duration: 2000});
                    this.loadingport=false;
                }
            });
            return decryptData
        },
        getcustomerPhoneView(customer_id,type,source,oid){
            if (this.loadingport) {
                return
            }
            this.loadingport=true;
            let decryptData='';
            let parmas = {customer_id,type,source,oid,};
            customerPhoneView(parmas).then(res => {
                if(res.data.code==200){
                    decryptData=res.data.data.decryptData;
                    window.location.href = "tel://" +decryptData;
                    this.loadingport=false;
                }else{
                    this.$toast.fail(res.data.msg,{duration: 2000});
                    this.loadingport=false;
                }
            });
        },
        //客户信息解密end
    }
}
</script>
<style scoped>
.flexDiv{
    display: flex;
    margin-bottom: 6px;
}
.payE{
  color:#f56c6c;
}
.payS{
  color:#67c23a;
}
.sendE{
  color:#e6a23c;
}
.sendS{
  color:#fac768;
}
.orderS{
  color:#409eff;
}
.orderE{
  color:#f56c6c;
}
</style>
<style>
.nut-cell .nut-cell-left{
    flex:none;
}
.nut-dialog-box{
    z-index: 1000;
}
.nut-dialog-footer{
    position: relative;
    z-index: 1000;
}
.nut-dialog-cancel{
    background: #f6f6f6;
}
</style>