<template>
    <!-- 登录 -->
    <div class="box">
        <div style="padding:0 0.2rem;background:#fff;height:100%;">
            <div style="width:1.5rem;margin:0 auto;padding-top:0.9rem;padding-bottom:0.6rem;">
                <img style="width:1.5rem;" src="../assets/imgs/logo-login.png" />
            </div>
            <nut-textinput v-model="formData.username" @change="userchange()" :has-border="true" placeholder="请输入手机号"/>
            <nut-textinput v-model="formData.password" :has-border="true" type="password" placeholder="请输入密码" style="margin-top:0.1rem;"/>
            <div v-if="phoneLogin" class="autoFucus" style="width:100%;padding: 10px 0;">
                <nut-textinput class="my-input" type="search" v-model="formData.code"  placeholder="请输入验证码" ref="myInput"/>
                <div class="searchBtn" @click="getCodeBtnDisableLogin?'':getCodeLogin()" :style="getCodeBtnDisableLogin?'':'color:rgb(43, 197, 143);'">
                    {{codeBtnWordLogin}}
                </div>
            </div>
            <nut-button v-if="isWeixin==true" @click="getbind()" block shape="circle">绑定</nut-button>
            <nut-button v-if="isWeixin==false" @click="getlogin()" block shape="circle">登录</nut-button>
        </div>
        <!-- <mt-field label="账号" placeholder="请输入账号" v-model="formData.username" style=""></mt-field>
        <mt-field label="密码" placeholder="请输入密码" type="password" v-model="formData.password"></mt-field>
        <mt-button type="primary" style="width:90%;">登录</mt-button> -->
    </div>
</template>
<script>
import { mapState } from "vuex";
import { requestLogin,wechatLogin,wechatBindUser,sendCode } from '@/api/api.js'
export default {
    components: {
    },
    data(){
        return {
            phoneLogin:false,
            recordsArr:[],
            formData:{
                username:'',
                password:'',
                originSign:'',
                code:'',
            },
            isWeixin:'',
            open_id:'',
            //登录获取验证码
            codeBtnWordLogin: '获取验证码', // 获取验证码按钮文字
            getCodeBtnDisableLogin:false,
            waitTimeLogin:61, // 获取验证码按钮失效时间
        }
    },
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {
        // console.log(localStorage.getItem('token'))
        var ua = navigator.userAgent.toLowerCase();
        this.isWeixin = ua.indexOf('micromessenger') != -1;
        if(this.isWeixin){
            if(localStorage.getItem('open_id')){
                this.open_id=localStorage.getItem('open_id');
            }
            // console.log(this.open_id,localStorage.getItem('open_id'));
            let appid = "wxfc6fdb371886e457"; //个人开发者appid
            let redirect = encodeURIComponent(window.location.href); //重定向回来的地址
            let wx_code = this.getUrlParam("code");//判断有没有code
            if (!wx_code) {
                //获取code的地址。获取成功重定向后地址栏中将会带有code，判断没有code的话，就跳转到微信官方链接上获取，获取成功后会再重定向回来，注意url是需要使用encodeURIComponent处理一下编码的
                window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect}&response_type=code&scope=snsapi_base&state=123#wechat_redirect`;
            }else{
                this.getwechatLogin(wx_code);
            }
            // alert('微信浏览器1.0',wx_code)
        }else{
            // alert('其他浏览器')  
            // this.$toast.fail("非微信浏览器请直接登陆",{duration: 2000});
            if(localStorage.getItem('token')){
                if(this.userInfo.roleIds.indexOf(4) != -1||this.userInfo.roleIds.indexOf(6) != -1){
                    this.$router.push('/orderList');
                }else{
                    this.$router.push('/customerList');
                }
            }else{
                if(localStorage.getItem('recordsArr')&&localStorage.getItem('recordsArr')!='undefined'){
                    this.recordsArr=JSON.parse(localStorage.getItem('recordsArr'));
                    // console.log(JSON.parse(localStorage.getItem('recordsArr')));
                    let records = this.recordsArr.findIndex(item => {
                        return item.phone == this.formData.username;
                    });
                    if(records==-1){
                        this.phoneLogin=true;
                        this.formData.originSign='';
                    }else{
                        this.formData.originSign=this.recordsArr[records].originSign;
                        this.phoneLogin=false;
                    }
                }else{
                    this.phoneLogin=true;
                    this.formData.originSign='';
                }
            }
        }
    },
    computed: {
      ...mapState(['userInfo']),
      phoneRegex() {
        return /^1[3456789]\d{9}$/;
      },
    },
    // 生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {
    },
    // 监控data中的数据变化
    watch: {},
    // 方法集合
    methods: {
        getUrlParam(name) {
            // console.log(window.location.search)
            var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
            var r = window.location.search.substr(1).match(reg);
            if (r != null) return unescape(r[2]);
            return null;
        },
        getwechatLogin(code){
            wechatLogin({code:code,open_id:this.open_id}).then(res => {
                if(res.data.code == 200){
                    this.open_id=res.data.data.open_id;
                    localStorage.setItem('open_id',this.open_id);
                    // alert('open_id1'+localStorage.getItem('open_id'));
                    if(res.data.data.user_bind_wechat_status==1){
                        //微信已绑定用户信息
                        localStorage.setItem('token', res.data.data.token);
                        this.$store.dispatch('getuserInfo',res.data.data);
                        localStorage.setItem('user_info',JSON.stringify(res.data.data));
                        if(res.data.data.roleIds.indexOf(4) != -1||res.data.data.roleIds.indexOf(6) != -1){
                            this.$router.push('/orderList');
                        }else{
                            this.$router.push('/customerList');
                        }
                    }else{
                        //微信未绑定用户信息
                        this.$toast.fail("请先进行登陆绑定",{duration: 2000});
                        localStorage.removeItem('token');
                        // localStorage.removeItem('open_id');
                    }
                }else{
                    this.$toast.fail(res.data.msg,{duration: 2000});
                }
            })
        },
        getbind(){
            // alert(this.open_id)
            let params = {
                username:this.formData.username,
                password:this.formData.password,
                open_id:this.open_id,
            }
            wechatBindUser(params).then(res => {
                if(res.data.code == 200){
                    // this.$toast.success("登陆成功",{duration: 2000});
                    localStorage.setItem('token', JSON.stringify(res.data.data.token));
                    this.$store.dispatch('getuserInfo',res.data.data);
                    sessionStorage.setItem('user_info',JSON.stringify(res.data.data));
                    if(res.data.data.roleIds.indexOf(4) != -1||res.data.data.roleIds.indexOf(6) != -1){
                        this.$router.push('/orderList');
                    }else{
                        this.$router.push('/customerList');
                    }
                }else{
                    this.$toast.fail(res.data.msg,{duration: 2000});
                    localStorage.removeItem('token');
                }
            })
        },
        getlogin(){
            let params = {
                username:this.formData.username,
                password:this.formData.password,
                originSign:this.formData.originSign,
                code:this.phoneLogin?this.formData.code:'',
            }
            requestLogin(params).then(res => {
                if(res.data.code == 200){
                    // this.$toast.success("登陆成功",{duration: 2000});
                    localStorage.setItem('token', JSON.stringify(res.data.data.token));
                    this.$store.dispatch('getuserInfo',res.data.data);
                    sessionStorage.setItem('user_info',JSON.stringify(res.data.data));

                    this.recordsArr=[];
                    if(localStorage.getItem('recordsArr')&&localStorage.getItem('recordsArr')!='undefined'){
                        this.recordsArr=JSON.parse(localStorage.getItem('recordsArr'));
                        let records = this.recordsArr.findIndex(item => {
                            return item.phone == this.formData.username;
                        });
                        // console.log(records);
                        if(records==-1){
                            this.recordsArr.push({phone:res.data.data.phone,originSign:res.data.data.origin_sign});
                            // console.log(this.recordsArr)
                            localStorage.setItem('recordsArr',JSON.stringify(this.recordsArr));
                        }else{
                            this.recordsArr[records].phone=res.data.data.phone;
                            this.recordsArr[records].originSign=res.data.data.origin_sign;
                            localStorage.setItem('recordsArr',JSON.stringify(this.recordsArr));
                        }
                    }else{
                        this.recordsArr=[{phone:res.data.data.phone,originSign:res.data.data.origin_sign}];
                        // console.log(this.recordsArr)
                        localStorage.setItem('recordsArr',JSON.stringify(this.recordsArr));
                    }
                    if(res.data.data.roleIds.indexOf(4) != -1||res.data.data.roleIds.indexOf(6) != -1){
                        this.$router.push('/orderList');
                    }else{
                        this.$router.push('/customerList');
                    }
                }
                else if(res.data.code == 10006){
                    this.$toast.fail('唯一标识错误或过期，需要验证码登陆',{duration: 2000});
                    this.phoneLogin=true;
                    localStorage.setItem('originSign','');
                    this.formData.originSign='';
                }else{
                    this.$toast.fail(res.data.msg,{duration: 2000});
                    localStorage.removeItem('token');
                }
            })
        },

        userchange(){
            if(localStorage.getItem('recordsArr')&&localStorage.getItem('recordsArr')!='undefined'){
                this.recordsArr=JSON.parse(localStorage.getItem('recordsArr'));
                // console.log(JSON.parse(localStorage.getItem('recordsArr')));
                let records = this.recordsArr.findIndex(item => {
                    return item.phone == this.formData.username;
                });
                // console.log(records);
                if(records==-1){
                    this.phoneLogin=true;
                    this.formData.originSign='';
                }else{
                    this.formData.originSign=this.recordsArr[records].originSign;
                    this.phoneLogin=false;
                }
            }else{
                this.phoneLogin=true;
                this.formData.originSign='';
            }
        },
        //登录获取验证码
        getCodeLogin(){
            if(this.formData.username==''){
                this.$toast.fail('请输入手机号',{duration: 2000});
                return false;
            }
            if(!this.phoneRegex.test(this.formData.username)){
                this.$toast.fail('请输入合法手机号',{duration: 2000});
                return false;
            }
            if(this.formData.username){
                sendCode({phone:this.formData.username}).then(res => {
                    if(res.data.code==200){
                        this.$toast.success("验证码发送成功,10分钟内有效",{duration: 2000});
                        // 因为下面用到了定时器，需要保存this指向
                        let that = this;
                        that.waitTimeLogin--;
                        that.getCodeBtnDisableLogin = true;
                        this.codeBtnWordLogin = `${this.waitTimeLogin}s 后重新获取`;
                        let timer = setInterval(function(){
                        if(that.waitTimeLogin>1){
                            that.waitTimeLogin--;
                            that.codeBtnWordLogin = `${that.waitTimeLogin}s 后重新获取`;
                        }else{
                            clearInterval(timer)
                            that.codeBtnWordLogin = '获取验证码';
                            that.getCodeBtnDisableLogin = false;
                            that.waitTimeLogin = 61;
                        }
                        },1000);
                    }else{
                        this.$toast.fail(res.data.msg,{duration: 2000});
                    }
                });
            }
        }
    }
}
</script>
<style scoped>
.box{
    height:100%;
}
</style>