var SIGN_REGEXP = /([yMdhsm])(\1*)/g;
var DEFAULT_PATTERN = 'yyyy-MM-dd';
function padding(s, len) {
  var len = len - (s + '').length;
  for (var i = 0; i < len; i++) {
    s = '0' + s;
  }
  return s;
}
function unique(arr) {
  const res = new Map();
  return arr.filter((arr) => !res.has(arr.id) && res.set(arr.id, 1));
}
export { unique };
export default {
  getQueryStringByName: function (name) {
    var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
    var r = window.location.search.substr(1).match(reg);
    var context = '';
    if (r != null) context = r[2];
    reg = null;
    r = null;
    return context == null || context == '' || context == 'undefined' ? '' : context;
  },
  formatDate: {
    format: function (date, pattern) {
      pattern = pattern || DEFAULT_PATTERN;
      return pattern.replace(SIGN_REGEXP, function ($0) {
        switch ($0.charAt(0)) {
          case 'y':
            return padding(date.getFullYear(), $0.length);
          case 'M':
            return padding(date.getMonth() + 1, $0.length);
          case 'd':
            return padding(date.getDate(), $0.length);
          case 'w':
            return date.getDay() + 1;
          case 'h':
            return padding(date.getHours(), $0.length);
          case 'm':
            return padding(date.getMinutes(), $0.length);
          case 's':
            return padding(date.getSeconds(), $0.length);
        }
      });
    },
    parse: function (dateString, pattern) {
      var matchs1 = pattern.match(SIGN_REGEXP);
      var matchs2 = dateString.match(/(\d)+/g);
      if (matchs1.length == matchs2.length) {
        var _date = new Date(1970, 0, 1);
        for (var i = 0; i < matchs1.length; i++) {
          var _int = parseInt(matchs2[i]);
          var sign = matchs1[i];
          switch (sign.charAt(0)) {
            case 'y':
              _date.setFullYear(_int);
              break;
            case 'M':
              _date.setMonth(_int - 1);
              break;
            case 'd':
              _date.setDate(_int);
              break;
            case 'h':
              _date.setHours(_int);
              break;
            case 'm':
              _date.setMinutes(_int);
              break;
            case 's':
              _date.setSeconds(_int);
              break;
          }
        }
        return _date;
      }
      return null;
    },
  },
  dateFormat: function (fmt, date) {
    if (!date) {
      return;
    }
    let ret;
    const opt = {
      'Y+': date.getFullYear().toString(), // 年
      'm+': (date.getMonth() + 1).toString(), // 月
      'd+': date.getDate().toString(), // 日
      'H+': date.getHours().toString(), // 时
      'M+': date.getMinutes().toString(), // 分
      'S+': date.getSeconds().toString(), // 秒
      // 有其他格式化字符需求可以继续添加，必须转化成字符串
    };
    for (let k in opt) {
      ret = new RegExp('(' + k + ')').exec(fmt);
      if (ret) {
        fmt = fmt.replace(ret[1], ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, '0'));
      }
    }
    return fmt;
  },
};

export function getCheckedId(_this, checkedCities, i_clickshow) {
  _this.checkedCitiesCopy = checkedCities;
  let copypeopleArr = [];
  let ischeckedCities = [];
  ischeckedCities = [..._this.checkedCities, ...checkedCities];
  copypeopleArr = [..._this.copypeople, ...checkedCities];
  _this.copypeopleCopy = unique(copypeopleArr);
  _this.checkedCities = unique(ischeckedCities);
  _this.checkedId = [];
  if (checkedCities.length > 0) {
    checkedCities.forEach((item) => {
      _this.checkedId.push(item.id);
    });
  } else {
    _this.checkedId = '';
  }

  _this.i_clickshow = i_clickshow;
}

//校验手机号码
export function isSpecialPhone(num) {
  return /^1[2,3,4,5,6,7,8,9]\d{9}$/.test(num);
}
//校验中英文姓名
export function isName(name) {
  return /[a-zA-Z\u4E00-\u9FA5]+$/.test(name);
}
//校验输入金额
export function isMoney(n) {
  return /^\d+$/.test(n);
}
// 纯数字验证
export function checkIsNumber(value) {
  const numberReg = /^[0-9]*$/;
  return numberReg.test(value);
}
//去空格
export function trim(str) {
  return str.replace(/(^\s*)|(\s*$)/g, '');
}
//遍历参数？后面的
export function formatParam(obj) {
  let str = '';
  for (let i in obj) {
    str += `${i}=${obj[i]}&`;
  }
  return str.slice(0, -1);
  // console.log(str)  比如{a:111,b:222,c:333}变为a=111&b=222&c=333
}
//获取地址栏url参数方式一
export function getQueryString(name) {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
  var r = window.location.search.substr(1).match(reg);
  if (r != null) {
    return unescape(r[2]);
  }
  return null;
}
//url参数处理(获取参数)方式二
export var URL = {
  set(key, value, url) {
    var reg = new RegExp('(' + key + ')=([^&]*)', 'ig');
    var url = url ? url : location.href;
    var result = reg.exec(url);
    if (result) {
      return url.replace(result[0], key + '=' + value);
    } else {
      var reg = /\?(.*)#?(.*)/gi;
      var search = reg.exec(url);
      if (search !== null) {
        return url.replace(search[1], search[1] + '&' + key + '=' + value);
      } else {
        return '';
      }
    }
  },
  get(key, url) {
    var reg = new RegExp('(' + key + ')=([^&]*)', 'ig');
    var url = url ? url : location.href;
    var result = reg.exec(url);
    if (result) {
      return result[2];
    } else {
      return '';
    }
  },
};
// 设备检测
export function IsPC() {
  let userAgentInfo = navigator.userAgent;
  let Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
  let flag = true; //true 代表PC false代表移动端
  for (var v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) != -1) {
      flag = false;
      break;
    }
  }
  return flag;
}
/* 倒计时（默认开始时间为当前时间）
 * @param endTime 结束时间
 * @returns 时间对象
 */
export function getEndTime(startTime, endTime) {
  const startDate = startTime || new Date(); //开始时间，当前时间
  const endDate = new Date(endTime); //结束时间，需传入时间参数
  const t = endDate.getTime() - startDate.getTime(); //时间差的毫秒数
  let d = 0,
    h = 0,
    m = 0,
    s = 0;
  if (t >= 0) {
    d = Math.floor(t / 1000 / 3600 / 24) > 10 ? Math.floor(t / 1000 / 3600 / 24) : '0' + Math.floor(t / 1000 / 3600 / 24);
    h = Math.floor((t / 1000 / 60 / 60) % 24) > 10 ? Math.floor((t / 1000 / 60 / 60) % 24) > 10 : '0' + Math.floor((t / 1000 / 60 / 60) % 24) > 10;
    m = Math.floor((t / 1000 / 60) % 60) > 10 ? Math.floor((t / 1000 / 60) % 60) : '0' + Math.floor((t / 1000 / 60) % 60);
    s = Math.floor((t / 1000) % 60) > 10 ? Math.floor((t / 1000) % 60) : '0' + Math.floor((t / 1000) % 60);
  }
  return {
    day: d,
    hour: h,
    minute: m,
    second: s,
  };
}
// 设置cookie  获取cookie 删除
export function setCookie(c_name, value, expire) {
  var date = new Date();
  date.setSeconds(date.getSeconds() + expire);
  document.cookie = c_name + '=' + escape(value) + '; expires=' + date.toGMTString(); //console.log(document.cookie)
}
export function getCookie(c_name) {
  if (document.cookie.length > 0) {
    let c_start = document.cookie.indexOf(c_name + '=');
    if (c_start != -1) {
      c_start = c_start + c_name.length + 1;
      let c_end = document.cookie.indexOf(';', c_start);
      if (c_end == -1) c_end = document.cookie.length;
      return unescape(document.cookie.substring(c_start, c_end));
    }
  }
  return '';
}
export function delCookie(c_name) {
  setCookie(c_name, '', -1);
}

// GBK字符集实际长度计算
export function getStrLeng(str){
  var realLength = 0;
  var len = str.length;
  var charCode = -1;
  for(var i = 0; i < len; i++){
      charCode = str.charCodeAt(i);
      if (charCode >= 0 && charCode <= 128) {
          realLength += 0.5;
      }else{
          // 如果是中文则长度加1
          realLength += 1;
      }
  }
  return Math.ceil(realLength);
}

// 根据关键字进行筛选、去重
export function uniqueByKey(arr,key) {
  let hash = {};
  let result = arr.reduce((total, currentValue) => {
      if (!hash[currentValue[key]]) { //如果当前元素的key值没有在hash对象里，则可放入最终结果数组
        hash[currentValue[key]]= true; //把当前元素key值添加到hash对象
        total.push(currentValue); //把当前元素放入结果数组
      }
      return total; //返回结果数组
    },[]);
  return result;
}

// 更改第一次出现的字符  - 只更改第一次出现的
export function del_html_tags(str,reallyDo,replaceWith) {
  var e=new RegExp(reallyDo,"g");
  let words = str.replace(e, replaceWith);
  return words;
}
export function group(array, subGroupLength) {
  let index = 0;
  let newArray = [];
  while(index < array.length) {
      newArray.push(array.slice(index, index += subGroupLength));
  }
  return newArray;
}
// 防抖
export function _debounce(fn, delay) {
  var timer;
  return function () {
      var _this = this;
      var args = arguments;
      if (timer) {
          clearTimeout(timer);
      }
      timer = setTimeout(function () {
          fn.apply(_this, args);
      }, delay);
  };
}
export function getDateStr(AddDayCount) { 
  var dd = new Date();
  dd.setDate(dd.getDate()+AddDayCount);//获取AddDayCount天后的日期
  var y = dd.getFullYear(); 
  var m = (dd.getMonth()+1)<10?"0"+(dd.getMonth()+1):(dd.getMonth()+1);//获取当前月份的日期，不足10补0
  var d = dd.getDate()<10?"0"+dd.getDate():dd.getDate();//获取当前几号，不足10补0
  return y+"-"+m+"-"+d; 
}
//判断一个数组是否包含另一个数组
export function isContainArr(parent, child) {
  return child.every(item => {
    return parent.some(v => {
      return item == v
    })
  })
}
function strReverse (str) {
	var newstring = "";
	for (var s=0; s < str.length; s++) {
		newstring = str.charAt(s) + newstring;
	}
	return newstring;
};
export function chkPass(pwd) {
	var nScore=0, nLength=0, nAlphaUC=0, nAlphaLC=0, nNumber=0, nSymbol=0, nMidChar=0, nRequirements=0,  nUnqChar=0, nRepChar=0, nRepInc=0, nConsecAlphaUC=0, nConsecAlphaLC=0, nConsecNumber=0, nConsecSymbol=0, nConsecCharType=0, nSeqAlpha=0, nSeqNumber=0, nSeqSymbol=0, nSeqChar=0, nReqChar=0;
	var nMultMidChar=2, nMultConsecAlphaUC=2, nMultConsecAlphaLC=2, nMultConsecNumber=2;
	var nMultSeqAlpha=3, nMultSeqNumber=3, nMultSeqSymbol=3;
	var nMultLength=4, nMultNumber=4;
	var nMultSymbol=6;
	var nTmpAlphaUC="", nTmpAlphaLC="", nTmpNumber="", nTmpSymbol="";
	var sAlphas = "abcdefghijklmnopqrstuvwxyz";
	var sNumerics = "01234567890";
	var sSymbols = ")!@#$%^&*()";
	var nMinPwdLen = 8;
	if (document.all) { var nd = 0; } else { var nd = 1; }
	if (pwd) {
		nScore = parseInt(pwd.length * nMultLength);
		nLength = pwd.length;
		var arrPwd = pwd.replace(/\s+/g,"").split(/\s*/);
		var arrPwdLen = arrPwd.length;
		
		for (var a=0; a < arrPwdLen; a++) {
			if (arrPwd[a].match(/[A-Z]/g)) {
				if (nTmpAlphaUC !== "") { if ((nTmpAlphaUC + 1) == a) { nConsecAlphaUC++; nConsecCharType++; } }
				nTmpAlphaUC = a;
				nAlphaUC++;
			}
			else if (arrPwd[a].match(/[a-z]/g)) { 
				if (nTmpAlphaLC !== "") { if ((nTmpAlphaLC + 1) == a) { nConsecAlphaLC++; nConsecCharType++; } }
				nTmpAlphaLC = a;
				nAlphaLC++;
			}
			else if (arrPwd[a].match(/[0-9]/g)) { 
				if (a > 0 && a < (arrPwdLen - 1)) { nMidChar++; }
				if (nTmpNumber !== "") { if ((nTmpNumber + 1) == a) { nConsecNumber++; nConsecCharType++; } }
				nTmpNumber = a;
				nNumber++;
			}
			else if (arrPwd[a].match(/[^a-zA-Z0-9_]/g)) { 
				if (a > 0 && a < (arrPwdLen - 1)) { nMidChar++; }
				if (nTmpSymbol !== "") { if ((nTmpSymbol + 1) == a) { nConsecSymbol++; nConsecCharType++; } }
				nTmpSymbol = a;
				nSymbol++;
			}
			var bCharExists = false;
			for (var b=0; b < arrPwdLen; b++) {
				if (arrPwd[a] == arrPwd[b] && a != b) {
					bCharExists = true;
					nRepInc += Math.abs(arrPwdLen/(b-a));
				}
			}
			if (bCharExists) { 
				nRepChar++; 
				nUnqChar = arrPwdLen-nRepChar;
				nRepInc = (nUnqChar) ? Math.ceil(nRepInc/nUnqChar) : Math.ceil(nRepInc); 
			}
		}
		for (var s=0; s < 23; s++) {
			var sFwd = sAlphas.substring(s,parseInt(s+3));
			var sRev = strReverse(sFwd);
			if (pwd.toLowerCase().indexOf(sFwd) != -1 || pwd.toLowerCase().indexOf(sRev) != -1) { nSeqAlpha++; nSeqChar++;}
		}
		
		for (var s=0; s < 8; s++) {
			var sFwd = sNumerics.substring(s,parseInt(s+3));
			var sRev = strReverse(sFwd);
			if (pwd.toLowerCase().indexOf(sFwd) != -1 || pwd.toLowerCase().indexOf(sRev) != -1) { nSeqNumber++; nSeqChar++;}
		}
		
		for (var s=0; s < 8; s++) {
			var sFwd = sSymbols.substring(s,parseInt(s+3));
			var sRev = strReverse(sFwd);
			if (pwd.toLowerCase().indexOf(sFwd) != -1 || pwd.toLowerCase().indexOf(sRev) != -1) { nSeqSymbol++; nSeqChar++;}
		}
		if (nAlphaUC > 0 && nAlphaUC < nLength) {	
			nScore = parseInt(nScore + ((nLength - nAlphaUC) * 2));
		}
		if (nAlphaLC > 0 && nAlphaLC < nLength) {	
			nScore = parseInt(nScore + ((nLength - nAlphaLC) * 2)); 
		}
		if (nNumber > 0 && nNumber < nLength) {	
			nScore = parseInt(nScore + (nNumber * nMultNumber));
		}
		if (nSymbol > 0) {	
			nScore = parseInt(nScore + (nSymbol * nMultSymbol));
		}
		if (nMidChar > 0) {	
			nScore = parseInt(nScore + (nMidChar * nMultMidChar));
		}
		if ((nAlphaLC > 0 || nAlphaUC > 0) && nSymbol === 0 && nNumber === 0) {  // Only Letters
			nScore = parseInt(nScore - nLength);
			nAlphasOnly = nLength;
		}
		if (nAlphaLC === 0 && nAlphaUC === 0 && nSymbol === 0 && nNumber > 0) {  // Only Numbers
			nScore = parseInt(nScore - nLength); 
			nNumbersOnly = nLength;
		}
		if (nRepChar > 0) {  // Same character exists more than once
			nScore = parseInt(nScore - nRepInc);
		}
		if (nConsecAlphaUC > 0) {  // Consecutive Uppercase Letters exist
			nScore = parseInt(nScore - (nConsecAlphaUC * nMultConsecAlphaUC)); 
		}
		if (nConsecAlphaLC > 0) {  // Consecutive Lowercase Letters exist
			nScore = parseInt(nScore - (nConsecAlphaLC * nMultConsecAlphaLC)); 
		}
		if (nConsecNumber > 0) {  // Consecutive Numbers exist
			nScore = parseInt(nScore - (nConsecNumber * nMultConsecNumber));  
		}
		if (nSeqAlpha > 0) {  // Sequential alpha strings exist (3 characters or more)
			nScore = parseInt(nScore - (nSeqAlpha * nMultSeqAlpha)); 
		}
		if (nSeqNumber > 0) {  // Sequential numeric strings exist (3 characters or more)
			nScore = parseInt(nScore - (nSeqNumber * nMultSeqNumber)); 
		}
		if (nSeqSymbol > 0) {  // Sequential symbol strings exist (3 characters or more)
			nScore = parseInt(nScore - (nSeqSymbol * nMultSeqSymbol)); 
		}
		var arrChars = [nLength,nAlphaUC,nAlphaLC,nNumber,nSymbol];
		nRequirements = nReqChar;
		if (pwd.length >= nMinPwdLen) { var nMinReqChars = 3; } else { var nMinReqChars = 4; }
		if (nRequirements > nMinReqChars) {  // One or more required characters exist
			nScore = parseInt(nScore + (nRequirements * 2)); 
		}
    return nScore
	}
}
export var docCookies = {
  getItem: function (sKey) {
    return decodeURIComponent(document.cookie.replace(new RegExp("(?:(?:^|.*;)\\s*" + encodeURIComponent(sKey).replace(/[-.+*]/g, "\\$&") + "\\s*\\=\\s*([^;]*).*$)|^.*$"), "$1")) || null;
  },
  setItem: function (sKey, sValue, vEnd, sPath, sDomain, bSecure) {
    if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) { return false; }
    var sExpires = "";
    if (vEnd) {
      switch (vEnd.constructor) {
        case Number:
          sExpires = vEnd === Infinity ? "; expires=Fri, 31 Dec 9999 23:59:59 GMT" : "; max-age=" + vEnd;
          break;
        case String:
          sExpires = "; expires=" + vEnd;
          break;
        case Date:
          sExpires = "; expires=" + vEnd.toUTCString();
          break;
      }
    }
    document.cookie = encodeURIComponent(sKey) + "=" + encodeURIComponent(sValue) + sExpires + (sDomain ? "; domain=" + sDomain : "") + (sPath ? "; path=" + sPath : "") + (bSecure ? "; secure" : "");
    return true;
  },
  removeItem: function (sKey, sPath, sDomain) {
    if (!sKey || !this.hasItem(sKey)) { return false; }
    document.cookie = encodeURIComponent(sKey) + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT" + ( sDomain ? "; domain=" + sDomain : "") + ( sPath ? "; path=" + sPath : "");
    return true;
  },
  hasItem: function (sKey) {
    return (new RegExp("(?:^|;\\s*)" + encodeURIComponent(sKey).replace(/[-.+*]/g, "\\$&") + "\\s*\\=")).test(document.cookie);
  },
  keys: /* optional method: you can safely remove it! */ function () {
    var aKeys = document.cookie.replace(/((?:^|\s*;)[^\=]+)(?=;|$)|^\s*|\s*(?:\=[^;]*)?(?:\1|$)/g, "").split(/\s*(?:\=[^;]*)?;\s*/);
    for (var nIdx = 0; nIdx < aKeys.length; nIdx++) { aKeys[nIdx] = decodeURIComponent(aKeys[nIdx]); }
    return aKeys;
  }
};

// 
export function conversionMoney(val) {
  let num=val?val.toString().split(".")[0]:0;
  let num1=num?num.split(','):0;
  let num2=num1?num1.join(''):0;
  let unit='';
  switch(num2.length){
    case 3:
      unit='百';
      break;
    case 4:
      unit='千';
      break;
    case 5:
      unit='万';
      break;
    case 6:
      unit='十万';
      break;
    case 7:
      unit='百万';
      break;
    case 8:
      unit='千万';
      break;
    case 9:
      unit='亿';
      break;
    case 10:
      unit='十亿';
      break;
    case 11:
      unit='百亿';
      break;
    case 12:
      unit='千亿';
      break;
  }
  return unit;
}
