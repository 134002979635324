import Vue from 'vue';
import Vuex from 'vuex';
import * as actions from './actions';
import * as getters from './getters';

Vue.use(Vuex);

// 应用初始状态
const state = {
  userInfo: JSON.parse(localStorage.getItem('user_info')) || '',
};
// 定义所需的 mutations
const mutations = {//存储用户信息
  getuserInfo(state, pro) {
    state.userInfo = pro;
    localStorage.setItem('user_info', JSON.stringify(pro));
  },
  clearUserInfo(state, pro) {
    state.userInfo = {};
  },
};

// 创建 store 实例
export default new Vuex.Store({
  actions,
  getters,
  state,
  mutations,
});
