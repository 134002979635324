<template>
    <!-- 首页 -->
     <!-- style="background:#fff;padding-bottom:0.7rem;height:100%;" -->
    <div style="background:#fff;padding-bottom:0.7rem;height:calc(100% - 0.7rem);">
        <router-view></router-view>
        <!-- <nut-tabbar @tab-switch="tabSwitch" :tabbar-list="tabList" :bottom="true"></nut-tabbar> -->
        <!-- <nut-tabbar 
        @tab-switch="tabSwitch"  
        :tabbar-list="tabList"
        ></nut-tabbar> -->
        <div class="div-tabbar bottom">
            <div class="div-tabbar-nav">
                <router-link to="/giftorderList">
                    <i  class="iconfont icon-dingdan" style="font-size:30px"></i>
                    <div style="display:block;">赠品审核</div>
                </router-link>
            </div>
            <div class="div-tabbar-nav">
                <router-link to="/customerList">
                    <i  class="iconfont icon-zhanghuxinxi" style="font-size:30px"></i>
                    <div style="display:block;">客户</div>
                </router-link>
            </div>
            <div class="div-tabbar-nav">
                <router-link to="/orderList">
                    <i  class="iconfont icon-dingdan" style="font-size:30px"></i>
                    <div style="display:block;">订单</div>
                </router-link>
            </div>
            <!-- <div class="div-tabbar-nav">
                <i  class="iconfont icon-zhanghu" style="font-size:30px"></i>
                <div style="display:block;">设置</div>
            </div> -->
            <!-- .icon-shezhi1 -->
        </div>
    </div>
</template>
<script>
import { mapState } from "vuex";
import { } from '@/api/api.js'
import common from '../common/js/util.js';
export default {
    components: {
    },
    data(){
        return {
            tabList:[
                {
                'tabTitle':'主页',
                'curr':true,
                'icon':'http://img13.360buyimg.com/uba/jfs/t1/29316/38/1115/3203/5c0f3d61E35d0c7da/9e557f2cb5c9dab6.jpg',
                'activeIcon':'http://img20.360buyimg.com/uba/jfs/t1/9996/36/8646/4833/5c0f3d61E7c1b7e0f/c98ad61124172e93.jpg',
                'href':'###'
                },
            ],
        }
    },
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {
        console.log(this.userInfo);
    },
    computed: {
      ...mapState(['userInfo']),
    },
    // 生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {
        let name = this.$store.state.userInfo && this.$store.state.userInfo.name ? this.$store.state.userInfo.name : '请先登录';
        let phone = this.$store.state.userInfo && this.$store.state.userInfo.phone ? this.$store.state.userInfo.phone : '请先登录';
        this.$watermark.set(name +'-' + common.dateFormat('YYmmdd', new Date()));
    },
    // 监控data中的数据变化
    watch: {},
    // 方法集合
    methods: {
        tabSwitch(){},
    }
}
</script>
<style scoped>
.div-tabbar{
    border: 0;
    border-bottom: 1px solid #eee;
    border-top: 1px solid #eee;
    width: 100%;
    display: flex;
    height: 0.7rem;
    box-sizing: border-box;
    background: #fff;
    font-size: 14px;
    justify-content: center;
    align-items:center;
}
.div-tabbar-nav {
    flex: 1;
    text-align: center;
    text-decoration: none;
    color: #848484;
    height: 100%;
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
}
.curr {
    color: #f0250f;
}
.bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 888;
}
.router-link-active{
    color:#f0250f;
}
</style>
<style>
.nut-collapse-item .collapse-item{
    background-color:#ebedf0 !important;
    border-bottom:1px solid #dcdfe6 !important;
}
</style>
