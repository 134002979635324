<template>
    <div style="height:100%;background:#fff;">
        <nut-navbar 
            style="background-color:#545456; color: #fff;"
            @on-click-back="navback" 
            :leftShow="true" 
            :rightShow="false"
        >订单信息</nut-navbar>
        <!-- <nut-buttongroup>
            <nut-button @click="callPhone()" type="light">打电话</nut-button>
            <nut-button @click="getCreatedOrder()">下单</nut-button>
        </nut-buttongroup> -->

        <nut-collapse v-model="activeNames" @change="callback">
            <nut-collapse-item title="客户信息" :name="1">
                <div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">客户名称</p>
                        <p @click="showName()" style="flex: 2;text-align:right;">
                            {{customer_nameCopy}}
                            <i :class="!customer_nameChange?'iconfont icon-biyanjing':'iconfont icon-yanjing'" style="color:#2bc58f;margin-left:0.06rem;"></i>
                        </p>
                    </div>
                    <!-- <div class="flexDiv">
                        <p style="color:#2d2d2d;">电话</p>
                        <p style="flex: 2;text-align:right;">{{orderDel.mobile}}</p>
                    </div> -->
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">年龄段</p>
                        <p style="flex: 2;text-align:right;">{{orderDel.customer_age}}</p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">客户症状</p>
                        <p style="flex: 2;text-align:right;">{{orderDel.customer_disease_remark}}</p>
                    </div>
                </div>
            </nut-collapse-item>
            <nut-collapse-item title="订单信息" :name="2">
                <div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">订单号</p>
                        <p style="flex: 2;text-align:right;">{{orderDel.oid}}</p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">订单状态</p>
                        <p style="flex: 2;text-align:right;">{{orderDel.status_detail}}</p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">订单类型</p>
                        <p style="flex: 2;text-align:right;">{{orderDel.purchase}}</p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">下单时间</p>
                        <p style="flex: 2;text-align:right;">{{orderDel.createTime}}</p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">支付时间</p>
                        <p style="flex: 2;text-align:right;">{{orderDel.payTime}}</p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">销售姓名</p>
                        <p style="flex: 2;text-align:right;">{{orderDel.admin_name}}</p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">销售备注</p>
                        <p style="flex: 2;text-align:right;">{{orderDel.admin_remark}}</p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">订单金额</p>
                        <p style="flex: 2;text-align:right;">{{orderDel.totalFee}}</p>
                    </div>
                </div>
            </nut-collapse-item>
            <nut-collapse-item v-if="orderDel.orderGoodsInfo.length" title="商品信息" :name="3">
                <div v-if="orderDel.itemType==1">
                    <div v-for="(item,index) in orderDel.orderGoodsInfo" :key="index">
                        <div class="flexDiv">
                            <p style="color:#2d2d2d;">商品类型</p>
                            <p style="flex: 2;text-align:right;">商品</p>
                        </div>
                        <div class="flexDiv">
                            <p style="color:#2d2d2d;">商品名称</p>
                            <p style="flex: 2;text-align:right;">{{item.goods_name}}</p>
                        </div>
                        <div class="flexDiv">
                            <p style="color:#2d2d2d;">商品规格</p>
                            <p style="flex: 2;text-align:right;">{{item.goods_attr}}</p>
                        </div>
                        <div class="flexDiv">
                            <p style="color:#2d2d2d;">数量</p>
                            <p style="flex: 2;text-align:right;">{{item.num}}</p>
                        </div>
                        <div class="flexDiv">
                            <p style="color:#2d2d2d;">价格</p>
                            <p style="flex: 2;text-align:right;">{{item.price}}</p>
                        </div>
                    </div>
                </div>
                <div v-if="orderDel.itemType==2">
                    <div v-for="(item,index) in orderDel.orderGoodsInfo" :key="index">
                        <div class="flexDiv">
                            <p style="color:#2d2d2d;">商品类型</p>
                            <p style="flex: 2;text-align:right;">套餐</p>
                        </div>
                        <div class="flexDiv">
                            <p style="color:#2d2d2d;">套餐名称</p>
                            <p style="flex: 2;text-align:right;">{{item.package_name}}</p>
                        </div>
                        <div class="flexDiv">
                            <p style="color:#2d2d2d;">套餐详情</p>
                            <div style="flex: 2;text-align:right;">
                                <div v-for="(goods,goodindex) in item.goods_detail" :key="goodindex">
                                    <p>商品:{{goods.goods_name}}</p>
                                    <p>规格：{{goods.goods_attr}}</p>
                                    <p>数量：{{goods.num}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="flexDiv">
                            <p style="color:#2d2d2d;">数量</p>
                            <p style="flex: 2;text-align:right;">{{item.package_quantity}}</p>
                        </div>
                        <div class="flexDiv">
                            <p style="color:#2d2d2d;">价格</p>
                            <p style="flex: 2;text-align:right;">{{item.package_price}}</p>
                        </div>
                    </div>
                </div>
            </nut-collapse-item>
            <nut-collapse-item v-if="orderDel.orderPayInfo.length" title="付款信息" :name="4">
                <div style="border-bottom:1px solid #ccc;padding-bottom:6px;padding-top:10px;">
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">订单金额</p>
                        <p style="flex: 2;text-align:right;">{{orderDel.totalFee}}</p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">应付金额</p>
                        <p style="flex: 2;text-align:right;">{{orderDel.payableFee}}</p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">已付金额</p>
                        <p style="flex: 2;text-align:right;">{{orderDel.payFee}}</p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">退款金额</p>
                        <p style="flex: 2;text-align:right;">{{orderDel.refundFee}}</p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">优惠金额</p>
                        <p style="flex: 2;text-align:right;">{{orderDel.discountFee}}</p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">优惠券到期时间</p>
                        <p style="flex: 2;text-align:right;">{{orderDel.discount_expire_date}}</p>
                    </div>
                </div>
                <p style="font-size:14px;color:#2c3e50;padding-top:10px;">支付信息：</p>
                <div v-for="(item,index) in orderDel.orderPayInfo" :key="index" style="border-bottom:1px solid #ccc;padding-bottom:6px;padding-top:10px;">
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">支付ID</p>
                        <p style="flex: 2;text-align:right;">{{item.order_sn}}</p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">支付交易号</p>
                        <p style="flex: 2;text-align:right;">{{item.aliPaySn}}</p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">应付金额</p>
                        <p style="flex: 2;text-align:right;">{{item.payableFee}}</p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">已付金额</p>
                        <p style="flex: 2;text-align:right;">{{item.payFee}}</p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">支付渠道</p>
                        <p style="flex: 2;text-align:right;">{{item.payTypeDetail}}</p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">支付状态</p>
                        <p style="flex: 2;text-align:right;">{{item.payStatusDetail}}</p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">支付时间</p>
                        <p style="flex: 2;text-align:right;">{{item.pay_date}}</p>
                    </div>
                    <div v-if="item.status==0" class="flexDiv">
                        <!-- <p style="color:#2d2d2d;"></p> -->
                        <p @click="getcancelOrderPay(item.order_sn)" style="color:#F56C6C;">取消本次支付</p>
                        <p @click="getcreateOrderPayCode(index)" style="flex: 2;text-align:right;color:#0abf89;">生成支付二维码</p>
                    </div>
                    <!-- &&(item.status==10||(item.status==20&&item.pay_order_num==1)) -->
                    <div v-if="userInfo.buttonStatus.orderStatusEdit==1&&item.pay_order_num==1" class="flexDiv" style="margin-top:20px;">
                        <p style="color:#2d2d2d;"></p>
                        <p @click="getFinanecePay(item)" style="flex: 2;text-align:right;color:#409eff;">确认支付</p>
                    </div>
                    <div v-if="item.status==1&&userInfo.buttonAuthList.indexOf('orderCancelPay') != -1">
                        <p style="color:#2d2d2d;"></p>
                        <p @click="getFinaneceCancelPay(item.order_sn)" style="flex: 2;text-align:right;color:#F56C6C;">取消支付-转单</p>
                    </div>
                </div>
            </nut-collapse-item>
            <div v-if="h5_pay_code" style="font-size:14px;background:#fff;padding:0 16px;">
                    <div class="flexDiv" style="line-height:0.24rem;">
                        <p style="color:#2d2d2d;">支付链接</p>
                        <p v-if="h5_pay_url" style="flex: 2;text-align:right;">
                            <span style="display:inline-block;width:2.2rem;white-space: nowrap;text-overflow:ellipsis;overflow:hidden;">{{h5_pay_url}}</span>
                            <span @click="copyUrl()" style="color:#409EFF;display:inline-block;">复制</span>
                        </p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">支付二维码</p>
                        <p @click="downloadImg()" v-if="h5_pay_code" style="flex: 2;text-align:right;color:#409EFF;">保存到手机</p>
                    </div>
                    <div>
                        <img style="width:100%;" :src="h5_pay_code" />
                    </div>
            </div>
            <nut-collapse-item title="退款信息" :name="5">
                <div v-for="(item,index) in orderDel.orderPayRefundInfo" :key="index" style="border-bottom:1px solid #ccc;padding-bottom:6px;padding-top:10px;">
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">退款单号</p>
                        <p style="flex: 2;text-align:right;">{{item.refund_sn}}</p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">退款交易单号</p>
                        <p style="flex: 2;text-align:right;">{{item.re_refund_sn}}</p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">已付金额</p>
                        <p style="flex: 2;text-align:right;">{{item.total_fee}}</p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">应退金额</p>
                        <p style="flex: 2;text-align:right;">{{item.refund_fee}}</p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">实退金额</p>
                        <p style="flex: 2;text-align:right;">{{item.actual_refund_fee}}</p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">退款渠道</p>
                        <p style="flex: 2;text-align:right;">{{item.payTypeDetail}}</p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">退款状态</p>
                        <p style="flex: 2;text-align:right;">{{item.payStatusDetail}}</p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">退款时间</p>
                        <p style="flex: 2;text-align:right;">{{item.refund_date}}</p>
                    </div>
                </div>
            </nut-collapse-item>
            <nut-collapse-item title="物流信息" :name="6">
                <div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">收货人</p>
                        <p @click="showConsignee()" style="flex: 2;text-align:right;">
                            {{consigneeCopy}}
                            <i :class="!consigneeChange?'iconfont icon-biyanjing':'iconfont icon-yanjing'" style="color:#2bc58f;margin-left:0.06rem;"></i>
                        </p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">手机号</p>
                        <p @click="showPhone()" style="flex: 2;text-align:right;">
                            {{mobileCopy}}
                            <i :class="!mobileChange?'iconfont icon-biyanjing':'iconfont icon-yanjing'" style="color:#2bc58f;margin-left:0.06rem;"></i>
                        </p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">地域</p>
                        <p style="flex: 2;text-align:right;">{{orderDel.province}}{{orderDel.city}}</p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">区县</p>
                        <p style="flex: 2;text-align:right;">{{orderDel.district}}</p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">地址</p>
                        <p @click="showStreet()" style="flex: 2;text-align:right;">
                            {{addressCopy}}
                            <i :class="!addressChange?'iconfont icon-biyanjing':'iconfont icon-yanjing'" style="color:#2bc58f;margin-left:0.06rem;"></i>
                        </p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">发货时间</p>
                        <p style="flex: 2;text-align:right;">{{orderDel.sendTime}}</p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">物流类型</p>
                        <p style="flex: 2;text-align:right;">{{orderDel.sendType}}</p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">物流单号</p>
                        <p style="flex: 2;text-align:right;">{{orderDel.sendCode}}</p>
                    </div>
                </div>
            </nut-collapse-item>
        </nut-collapse>
        <nut-dialog title="继续支付" :visible="dialogShow" :closeBtn="true" :noFooter="true" @ok-btn-click="dialogShow=false" @cancel-btn-click="dialogShow=false" @close="dialogShow=false">
            <a href="javascript:;" @click="dialogShow=false" :noCancelBtn="true">点我可以直接关闭对话框</a>
        </nut-dialog>
        <nut-dialog title="确认支付" :visible="dialogShowFinanece" :noFooter="true" :noOkBtn="true" :noCancelBtn="true"  @close="onCancelBtnFinanece()">
            <nut-cell :show-icon="false" title="订单号" :desc="finanecePayForm.oid"></nut-cell>
            <nut-cell :show-icon="false" title="支付ID" :desc="finanecePayForm.order_sn"></nut-cell>
            <nut-cell :show-icon="false" title="应付金额" :desc="finanecePayForm.payableFee"></nut-cell>
            <nut-cell :show-icon="true" title="请选择支付时间" :desc="finanecePayForm.payTime" @click.native="openSwitch('isVisible2')"></nut-cell>
            <nut-datepicker 
                :is-visible="isVisible2"
                title="请选择支付时间" 
                type="datetime"
                startDate="2000-11-10 12:08"
                :endDate="endDate"
                :defaultValue="defaultValue"
                @close="switchPicker('isVisible2')"
                @choose="setChooseValue2"
            >
            </nut-datepicker>
            <div class="nut-dialog-footer" style="margin-top:20px;">
                <button @click="onCancelBtnFinanece()" class="nut-dialog-btn nut-dialog-cancel">取 消</button> 
                <button @click="onOkBtnFinanece()" class="nut-dialog-btn nut-dialog-ok">确 定</button>
            </div>
        </nut-dialog>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { myOrderDetail,createOrderPayCode,cancelOrderPay,customerView,customerPhoneView,setMyOrderStatus,orderCancelPay } from '@/api/api.js'
import common from '../common/js/util.js';
export default {
    components: {
    },
    data(){
        return {
            orderId:this.$route.query.id,
            customer_nameCopy:'',
            customer_nameChange:false,
            consigneeCopy:'',
            consigneeChange:false,
            mobileCopy:'',
            mobileChange:false,
            addressCopy:'',
            addressChange:false,
            orderDel:{
                orderGoodsInfo:[],
                orderPayInfo:[],
                orderPayRefundInfo:[],
            },
            activeNames: [1,2,3,4],
            h5_pay_url:'',
            h5_pay_code:'',
            dialogShow:false,
            loadingport:false,

            dialogShowFinanece:false,
            isVisible2: false,
            datetime:'',
            defaultValue:'',
            endDate:'',
            finanecePayForm:{
                oid:'',
                order_sn:'',
                payableFee:'',
                payTime:'请选择',
                type:2,
                source:2,
            },
            submitport:false,
        }
    },
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {
        this.endDate = this.getweek(0)+' 23:59';
        this.defaultValue=this.getweek(0)+' '+this.updateTime();
        console.log(this.defaultValue)
        this.getmyOrderDetail();
    },
    computed: {
      ...mapState(['userInfo']),
    },
    // 生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {
        let name = this.$store.state.userInfo && this.$store.state.userInfo.name ? this.$store.state.userInfo.name : '请先登录';
        let phone = this.$store.state.userInfo && this.$store.state.userInfo.phone ? this.$store.state.userInfo.phone : '请先登录';
        this.$watermark.set(name +'-' + common.dateFormat('YYmmdd', new Date()));
    },
    // 监控data中的数据变化
    watch: {},
    // 方法集合
    methods: {
        navback(){
            this.$router.push({
                path:'/orderList'
            })
        },
        callback(name) {
            // console.log(activeNames, `点击了name是${name}的面板,callback`);
        },
        callPhone(phoneNumber) {
            // console.log(this.orderDel.mobile);
            // window.location.href = "tel://" + this.orderDel.mobile;
            this.getcustomerPhoneView(this.orderDel.customer_id,2,3,this.orderDel.oid);
        },
        getCreatedOrder(){
            this.$router.push({
                path:'/createdOrder',
                query:{
                    id:this.orderDel.customer_id
                }
            })
        },
        //订单详情
        getmyOrderDetail() {
            myOrderDetail({oid:this.orderId}).then(res => {
                if(res.data.code==200){
                    console.log(res.data.data)
                    this.orderDel=res.data.data.orderInfo;
                    this.customer_nameCopy=this.orderDel.desensitise_customer_name;
                    this.consigneeCopy=this.orderDel.desensitise_consignee;
                    this.mobileCopy=this.orderDel.desensitise_mobile;
                    this.addressCopy=this.orderDel.desensitise_address;
                    this.orderDel.orderGoodsInfo=res.data.data.orderGoodsInfo;
                    this.orderDel.orderPayInfo=res.data.data.orderPayInfo;
                    this.orderDel.orderPayRefundInfo=res.data.data.orderPayRefundInfo;
                }else{
                    this.$toast.fail(res.data.msg,{duration: 2000});
                }
            });
        },

        // 未付款重新生成订单二维码
        getcreateOrderPayCode(index){
            createOrderPayCode({oid:this.orderId}).then(res => {
                if(res.data.code==200){
                    // this.orderDel.orderPayInfo[index].h5_pay_url=res.data.data.h5_pay_url;
                    // this.orderDel.orderPayInfo[index].h5_pay_code=res.data.data.h5_pay_code;
                    // console.log(this.orderDel.orderPayInfo[index]);
                    // this.orderDel.orderPayInfo=this.orderDel.orderPayInfo;
                    this.h5_pay_url=res.data.data.h5_pay_url;
                    this.h5_pay_code=res.data.data.h5_pay_code;
                }else{
                    this.$toast.fail(res.data.msg,{duration: 2000});
                }
            });
        },
        copyUrl(){
            let that=this;
            this.$copyText(this.h5_pay_url).then(function(){
                that.$toast.success("支付链接复制到剪切板",{duration: 2000});
            }, function(e) {

            });
        },
        downloadImg(){
            var ua = navigator.userAgent.toLowerCase();
            var isWeixin = ua.indexOf('micromessenger') != -1;
            if(isWeixin){
                this.$toast.success("请长按图片保存",{duration: 2000});
            }else{
                let a = document.createElement('a');
                a.style.display = 'none';
                a.href = this.h5_pay_code;
                a.setAttribute('download', '支付二维码.jpg')
                document.body.appendChild(a);
                a.click(); //执行下载
                window.URL.revokeObjectURL(a.href);
                document.body.removeChild(a);
            }
        },
        //取消支付
        getcancelOrderPay(order_sn){
            let that=this;
            this.$dialog({
                title: "此操作将取消支付, 是否继续?",
                // content: "小屏或移动端浏览效果最佳",
                closeBtn:true,  //显式右上角关闭按钮
                onOkBtn(event) {  //确定按钮点击事件
                    this.close(); //关闭对话框
                    cancelOrderPay({order_sn:order_sn,source:2}).then(res => {
                        if(res.data.code==200){
                            that.$toast.success("取消成功",{duration: 2000});
                            that.h5_pay_url='';
                            that.h5_pay_code='';
                            that.getmyOrderDetail();
                        }else{
                            that.$toast.fail(res.data.msg,{duration: 2000});
                        }
                    });
                },
                onCancelBtn(event) {  //取消按钮点击事件，默认行为关闭对话框
                    //return false;  //阻止默认“关闭对话框”的行为
                },
                onCloseBtn(event) { //右上角关闭按钮点击事件
                    //return false;  //阻止默认“关闭对话框”的行为
                },
                closeCallback(target) {
                    // alert("will close");  //对话框关闭回调函数，无论通过何种方式关闭都会触发
                }
            });
        },
        //确认支付（财务）
        getFinanecePay(data){
            this.finanecePayForm.oid=this.orderDel.oid;
            this.finanecePayForm.order_sn=data.order_sn;
            this.finanecePayForm.payableFee=data.payableFee;
            this.finanecePayForm.payTime='请选择';
            this.dialogShowFinanece=true;
            console.log(this.dialogShowFinanece);
        },
        openSwitch(param) {
            this[`${param}`] = true;
        },
        switchPicker(param) {
            this[`${param}`] = !this[`${param}`];
        },
        setChooseValue2(param) {
            this.datetime = param[5];
            console.log(this.datetime);
            this.finanecePayForm.payTime=this.datetime;
        },
        onOkBtnFinanece(){
            if (this.submitport) {
                return
            }
            this.submitport=true;
            if(this.finanecePayForm.payTime=='请选择'){
                this.$toast.fail('请选择支付时间',{duration: 2000});
                return;
            }
            let parmas=this.finanecePayForm;
            setMyOrderStatus(parmas).then(res => {
              if(res.data.code==200){
                this.dialogShowFinanece=false;
                this.$toast.success("修改支付状态成功",{duration: 2000});
                this.submitport=false;
                this.getmyOrderDetail();
              }else{
                this.$toast.fail(res.data.msg,{duration: 2000});
                this.submitport=false;
              }
            });
        },
        onCancelBtnFinanece(){
            this.dialogShowFinanece=false;
        },
        //确认支付（财务）end
        //取消支付-转单（财务）
        getFinaneceCancelPay(order_sn){
            let that=this;
            this.$dialog({
                title: "此操作将已支付修改未支付, 是否继续?",
                // content: "小屏或移动端浏览效果最佳",
                closeBtn:true,  //显式右上角关闭按钮
                onOkBtn(event) {  //确定按钮点击事件
                    this.close(); //关闭对话框
                    orderCancelPay({order_sn:order_sn,source:2}).then(res => {
                        if(res.data.code==200){
                            that.$toast.success("取消支付成功",{duration: 2000});
                            that.getmyOrderDetail();
                        }else{
                            that.$toast.fail(res.data.msg,{duration: 2000});
                        }
                    });
                },
                onCancelBtn(event) {  //取消按钮点击事件，默认行为关闭对话框
                    //return false;  //阻止默认“关闭对话框”的行为
                },
                onCloseBtn(event) { //右上角关闭按钮点击事件
                    //return false;  //阻止默认“关闭对话框”的行为
                },
                closeCallback(target) {
                    // alert("will close");  //对话框关闭回调函数，无论通过何种方式关闭都会触发
                }
            });
        },

        //客户信息解密
        async showName(data){
            if(!this.customer_nameChange){
                let returnData=await this.getcustomerView(this.orderDel.customer_id,1,3,this.orderDel.oid);
                this.customer_nameCopy=returnData;
                this.customer_nameChange=returnData?true:false;
            }else{
                this.customer_nameCopy=this.orderDel.desensitise_customer_name;
                this.customer_nameChange=false;
            }
        },
        async showConsignee(data){
            if(!this.consigneeChange){
                let returnData=await this.getcustomerView(this.orderDel.customer_id,1,2,this.orderDel.oid);
                this.consigneeCopy=returnData;
                this.consigneeChange=returnData?true:false;
            }else{
                this.consigneeCopy=this.orderDel.desensitise_consignee;
                this.consigneeChange=false;
            }
        },
        async showPhone(data){
            if(!this.mobileChange){
                let returnData=await this.getcustomerView(this.orderDel.customer_id,2,2,this.orderDel.oid);
                this.mobileCopy=returnData;
                this.mobileChange=returnData?true:false;
            }else{
                this.mobileCopy=this.orderDel.desensitise_mobile;
                this.mobileChange=false;
            }
        },
        async showStreet(data){
            if(!this.addressChange){
                let returnData=await this.getcustomerView(this.orderDel.customer_id,3,2,this.orderDel.oid);
                this.addressCopy=returnData;
                this.addressChange=returnData?true:false;
            }else{
                this.addressCopy=this.orderDel.desensitise_address;
                this.addressChange=false;
            }
        },
        copyText(phone){
            let that=this;
            this.$copyText(phone).then(function(){
                that.$toast.success("内容已复制到剪切板!",{duration: 2000});
            }, function(e) {

            });
        },
        async getcustomerView(customer_id,type,source,oid){
            if (this.loadingport) {
                return
            }
            this.loadingport=true;
            let decryptData='';
            let parmas = {customer_id,type,source,oid,};
            await customerView(parmas).then(res => {
                if(res.data.code==200){
                    decryptData=res.data.data.decryptData;
                    this.loadingport=false;
                }else{
                    this.$toast.fail(res.data.msg,{duration: 2000});
                    this.loadingport=false;
                }
            });
            return decryptData
        },
        getcustomerPhoneView(customer_id,type,source,oid){
            if (this.loadingport) {
                return
            }
            this.loadingport=true;
            let decryptData='';
            let parmas = {customer_id,type,source,oid,};
            customerPhoneView(parmas).then(res => {
                if(res.data.code==200){
                    decryptData=res.data.data.decryptData;
                    window.location.href = "tel://" +decryptData;
                    this.loadingport=false;
                }else{
                    this.$toast.fail(res.data.msg,{duration: 2000});
                    this.loadingport=false;
                }
            });
        },
        //客户信息解密end

        getweek(day){
            var today = new Date();
            var targetday_milliseconds=today.getTime() + 1000*60*60*24*day;
            today.setTime(targetday_milliseconds);
            var tYear = today.getFullYear();
            var tMonth = today.getMonth();
            var tDate = today.getDate();
            tMonth = this.doHandleMonth(tMonth + 1);
            tDate = this.doHandleMonth(tDate);
            return `${tYear}-${tMonth}-${tDate}`;
        },
        doHandleMonth(month){
            var m = month;
            if(month.toString().length == 1){
                m = "0" + month;
            }
            return m;
        },
        updateTime() {
            const now = new Date();
            const hour = now.getHours();
            const minute = now.getMinutes();
            const second = now.getSeconds();
            // return hour + ":" + minute + ":" + second;
            return hour + ":" + minute;
        },
    }
}
</script>
<style scoped>
.flexDiv{
    display: flex;
    margin-bottom: 6px;
}
.payE{
  color:#f56c6c;
}
.payS{
  color:#67c23a;
}
.sendE{
  color:#e6a23c;
}
.sendS{
  color:#fac768;
}
.orderS{
  color:#409eff;
}
.orderE{
  color:#f56c6c;
}
</style>
<style>
.nut-cell .nut-cell-left{
    flex:none;
}
.nut-dialog-box{
    z-index: 1000;
}
.nut-dialog-footer{
    position: relative;
    z-index: 1000;
}
.nut-dialog-cancel{
    background: #f6f6f6;
}
</style>