<template>
    <!-- 下单 -->
    <div class="creatOrder">
        <nut-navbar 
            style="background-color:#545456; color: #fff;"
            @on-click-back="navback" 
            :leftShow="true" 
            :rightShow="false"
        >创建订单</nut-navbar>
        <div style="padding:10px;">
            <div class="autoInput">
                <nut-textinput v-model="orderform.consigneeCopy" :disabled="orderform.consignee_change==2?true:false"
                 label="姓名" :has-border="false" :clear-btn="false" placeholder="请输入姓名" style="flex: 2;"/>
                <div v-if="orderform.consigneeCopy" @click="showConsignee()" class="searchBtn">
                    <i :class="orderform.consignee_change==2?'iconfont icon-biyanjing':'iconfont icon-yanjing'" style="color:#2bc58f;margin-left:0.06rem;"></i>
                </div>
            </div>
            <!-- <nut-textinput v-model="orderform.consignee" label="姓名" :has-border="false" placeholder="请输入姓名" style="border-bottom:1px solid rgba(218,218,218,0.5);"/> -->

            <div class="autoInput">
                <nut-textinput v-model="orderform.mobileCopy" :disabled="orderform.mobile_change==2?true:false" 
                label="手机号" :has-border="false" :clear-btn="false" placeholder="请输入手机号" style="flex: 2;"/>
                <div v-if="orderform.mobileCopy" @click="showPhone()" class="searchBtn">
                    <i :class="orderform.mobile_change==2?'iconfont icon-biyanjing':'iconfont icon-yanjing'" style="color:#2bc58f;margin-left:0.06rem;"></i>
                </div>
            </div>
            <!-- <nut-textinput v-model="orderform.mobile" @click.native="showKeyBoard" placeholder="请输入手机号" :has-border="false" readonly label="手机号" style="border-bottom:1px solid rgba(218,218,218,0.5);"/> -->
            <nut-numberkeyboard :visible="mobileVisible" v-model="orderform.mobile" :has-border="false" maxlength="11" @close="closeMobile"></nut-numberkeyboard>
            <nut-textinput v-model="orderform.customer_disease_remark" label="客户症状" :has-border="false" :clear-btn="false" placeholder="请输入客户症状"/>
            <nut-cell :show-icon="true"  title="年龄阶段" :desc="orderform.customer_ageName" @click.native="openSwitchAge('ageIsVisible')"></nut-cell>
            <nut-picker :is-visible="ageIsVisible" :list-data="ageoptions" title="请选择年龄段" @close="closeSwitchAge('ageIsVisible')" @confirm="confirmAge"></nut-picker>
            <nut-textinput v-model="orderform.province" label="省" :has-border="false" :clear-btn="false" placeholder="省" style="border-bottom:1px solid rgba(218,218,218,0.5);"/>
            <nut-textinput v-model="orderform.city" label="市" :has-border="false" :clear-btn="false" placeholder="市" style="border-bottom:1px solid rgba(218,218,218,0.5);"/>
            <nut-textinput v-model="orderform.district" label="区/县" :has-border="false" :clear-btn="false" placeholder="区/县" style="border-bottom:1px solid rgba(218,218,218,0.5);"/>
            <div class="autoInput">
                <nut-textinput v-model="orderform.addressCopy" :disabled="orderform.address_change==2?true:false"
                 label="详细地址" :has-border="false" :clear-btn="false" placeholder="街道门牌、楼层房间等详细信息" style="flex: 2;"/>
                <div v-if="orderform.addressCopy" @click="showStreet()" class="searchBtn">
                    <i :class="orderform.address_change==2?'iconfont icon-biyanjing':'iconfont icon-yanjing'" style="color:#2bc58f;margin-left:0.06rem;"></i>
                </div>
            </div>
            <!-- <nut-textinput v-model="orderform.address" label="详细地址" :has-border="false" placeholder="街道门牌、楼层房间等详细信息" style="border-bottom:1px solid rgba(218,218,218,0.5);"/> -->
        </div>
        <div>
            <p class="p_title">选择商品/套餐</p>
            <nut-tab @tab-switch="tabSwitch" :def-index="defIndex" :line-width="20">
                <nut-tab-panel tab-title="商品">
                    <div class="autoFucus">
                        <nut-textinput  class="my-input" type="search" v-model="searchform.name"  placeholder="请输入搜索内容" ref="myInput" @keyup.enter="submit" >
                            <template v-slot:clearBtn>
                                <nut-icon type="cross"></nut-icon>
                            </template>
                        </nut-textinput>
                        <div class="searchBtn" @click="submit">搜索</div>
                    </div>
                    <!-- 商品 -->
                    <div v-if="orderform.itemType==1">
                        <div v-for="(goods,goodsInd) in goodsCouponList" :key="goodsInd" class="boxflex">
                            <div class="item">
                                <p>名称：{{goods.name}}{{goods.sku.length}}</p>
                                <div v-for="(item,index) in goods.sku" :key="index">
                                    <p :style="index==0?'':'padding-top:10px;'">规格：{{item.attr}}</p>
                                    <p>单价：{{item.sales_price}}</p>
                                </div>
                            </div>
                            <div class="divNum">
                                <div v-for="(item1,index) in goods.sku" :key="index">
                                    <nut-textinput v-model="item1.num" @click.native="showKeyBoardNum(goodsInd,index)" :has-border="true" :clear-btn="false" readonly label="" placeholder="请输入内容"/>
                                    <p :style="(index+1)==goods.sku.length?'':'border-bottom: 1px solid #ccc;padding-bottom:10px;'">
                                        小计：{{item1.totalPrice}}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </nut-tab-panel>
                <nut-tab-panel tab-title="套餐">
                    <!-- 套餐 -->
                    <div v-if="orderform.itemType==2">
                        <div v-for="(goods,goodsInd) in goodsCouponList" :key="goodsInd" class="boxflex">
                            <div class="item">
                                <p>名称：{{goods.package_name}}</p>
                                <p style="margin-top:10px;">单价：{{goods.package_price}}</p>
                                <div v-for="(item,index) in goods.goods_package_sku" :key="index">
                                    <p>商品：{{item.goods_name}}</p>
                                    <p :style="index==0?'':'padding-top:10px;'">规格：{{item.goods_attr}}</p>
                                    <p :style="(index+1)==goods.goods_package_sku.length?'':'border-bottom: 1px solid #ccc;padding-bottom:10px;'">数量：{{item.num}}</p>
                                </div>
                            </div>
                            <div class="divNum">
                                <nut-textinput v-model="goods.num" @click.native="showKeyBoardPackageNum(goodsInd,index)" :has-border="true" :clear-btn="false" readonly label="" placeholder="请输入内容" style="width:100%;"/>
                                <p>
                                    小计：{{goods.totalPrice}}
                                </p>
                            </div>
                        </div>
                    </div>
                </nut-tab-panel>
            </nut-tab>
            <nut-numberkeyboard
                :visible="numVisible"
                @input="inputNum"
                @delete="deleteNum"
                @close="closeNum"
            ></nut-numberkeyboard>
        </div>
        <div style="padding:10px;background: #fff;font-size:14px;">
            <span style="display:inline-block;line-height:36px;font-size:14px;">是否有赠品：</span>
            <nut-radiogroup @radio-check="handleRadioChange" v-model="orderform.is_gift" direction="horizontal" style="display:inline-block;">
                <nut-radio label="1">是</nut-radio>
                <nut-radio label="2">否</nut-radio>
            </nut-radiogroup>
            <div v-show="orderform.is_gift == 1" v-for="(item,index) in orderform.gift_data" :key="item.key">
                <nut-cell :show-icon="true" :title="'赠品'+(index+1)" :desc="item.gift_idName" @click.native="openSwitchGift('giftIsVisible',index)"></nut-cell>
                <nut-picker :is-visible="giftIsVisible" :list-data="giftList" title="请选择赠品" 
                @close="closeSwitchGift('giftIsVisible')" @confirm="confirmGift"></nut-picker>
                <div class="giftNum" :style="orderform.gift_data.length>1&&index==0?'margin-bottom:10px;':''">
                    <nut-stepper :simple="false" :value.sync="item.num" :min="1"></nut-stepper>
                    <p>
                        <nut-button @click="addGift()" type="actived" shape="circle" icon="plus" small color="#1989fa" style="border-color:#1989fa;margin-right:6px;"></nut-button>     
                        <nut-button v-if="orderform.gift_data.length>1" @click="delGift(index)" type="actived" shape="circle" icon="minus" small></nut-button>     
                    </p>
                </div>

                <!-- <van-field
                    readonly
                    clickable
                    :name="item.name"
                    :value="item.value"
                    :label="item.label"
                    placeholder="点击选择城市"
                    @click="showPicker(index)"
                />
                <van-popup v-model="showPickerIndex" position="bottom">
                    <van-picker
                        show-toolbar
                        :columns="columns"
                        @confirm="onConfirm"
                        @cancel="showPickerIndex = -1"
                    />
                </van-popup>             -->
            </div>
            <p style="color:#F56C6C;font-size:12px;padding:10px 0;">
                优惠券有效期15分钟，超时未支付，优惠券失效，请重新下单
            </p>
            <nut-cell :show-icon="true"  title="优惠券" :desc="orderform.coupon_idName" @click.native="openSwitchCoupon('couponIsVisible')"></nut-cell>
            <nut-picker :is-visible="couponIsVisible" :list-data="couponList" title="请选择优惠券" 
            @close="closeSwitchCoupon('couponIsVisible')" @confirm="confirmCoupon"></nut-picker>


            <nut-cell :show-icon="true"  title="支付方式" :desc="orderform.payTypeName" @click.native="openSwitchPay('payIsVisible')"></nut-cell>
            <nut-picker :is-visible="payIsVisible" :list-data="payOptions" title="请选择支付方式" 
            @close="closeSwitchPay('payIsVisible')" @confirm="confirmPay"></nut-picker> 
            <!-- @click.native="showKeyBoardAmount" -->
            <div v-if="totalAmount" class="flexDiv" style="padding:20px 0;">
                <p style="color:#2d2d2d;">应付总额</p>
                <p style="flex: 2;text-align:right;">
                    <span style="color:#ff4f18;">
                        {{orderform.coupon_idName!='请选择优惠券'?'￥'+(Number(totalAmount)-Number(orderform.coupon_idName)):'￥'+Number(totalAmount)}}
                    </span>
                    （{{totaltGoods}}）
                </p>
            </div>
            <nut-textinput v-if="orderform.pay_type==='0'" v-model="orderform.pay_price" :has-border="true" label="支付金额" placeholder="请输入"/>
            <p style="font-size:14px;padding:10px 0px 0px;">
                销售标签
            </p>
            <nut-checkboxgroup :vertical="true"
                ref="checkboxGroup"
                :checkBoxData="selltagoptions"
                v-model="orderform.tag_list">
            </nut-checkboxgroup>
            <p style="font-size:14px;padding:10px 0px 10px;border-top:1px solid #ececee;">备注</p>
            <nut-textbox v-model="orderform.remark" :max-num="200"></nut-textbox>
            <!-- <nut-numberkeyboard
                :visible="amountVisible"
                v-model="orderform.pay_price"
                @input="inputAmount"
                @close="closeAmount"
                :custom-key="customKey"
            ></nut-numberkeyboard> -->
            <!-- <van-number-keyboard
            :show="amountVisible"
            theme="custom"
            :extra-key="['.']"
            close-button-text="完成"
            @blur="amountVisible = false"
            @input="inputAmount"
            /> -->
            <!-- <div v-if="h5_pay_url" class="flexDiv" style="line-height:0.24rem;">
                <p style="color:#2d2d2d;">支付链接</p>
                <p style="flex: 2;text-align:right;">
                    <span style="display:inline-block;width:2.2rem;white-space: nowrap;text-overflow:ellipsis;overflow:hidden;">{{h5_pay_url}}</span>
                    <span @click="copyUrl()" style="color:#409EFF;display:inline-block;">复制</span>
                </p>
            </div>
            <div v-if="h5_pay_code" class="flexDiv">
                <p style="color:#2d2d2d;">支付二维码</p>
                <p @click="downloadImg()" style="flex: 2;text-align:right;color:#409EFF;">保存到手机</p>
            </div> -->
            <div>
                <img style="width:100%;" :src="h5_pay_code" />
            </div>
            <nut-button style="margin-top:0.4rem;margin-bottom:0.4rem;" v-if="!h5_pay_url&&!h5_pay_code" @click="submitOrder()" block shape="circle">提交</nut-button>
        </div>
        <!-- <nut-radiogroup v-model="orderform.pay_type" size="small">
            <nut-radio :label="1">全额支付</nut-radio>
            <nut-radio :label="0">分期支付</nut-radio>
        </nut-radiogroup> -->
    </div>
</template>
<script>
import { mapState } from "vuex";
import { customerDetail,getGoodsCouponList,goodsList,getCouponList,createOrder,customerView,selltagList } from '@/api/api.js'
import common from '../common/js/util.js';
export default {
    components: {
    },
    data(){
        return {
            customerId:this.$route.query.id,
            customKey: ['.'],
            orderform:{
                customer_id:this.$route.query.id,
                consignee:'',
                consigneeCopy:'',
                consignee_change:2,
                mobile:'',
                mobileCopy:'',
                mobile_change:2,
                customer_disease_remark:'',
                customer_ageName: '请选择年龄段',
                customer_age:'',
                province:'',
                city:'',
                district:'',
                address:'',
                addressCopy:'',
                address_change:2,
                goods:[],
                coupon_idName:'请选择优惠券',
                coupon_id:'',
                payTypeName:'请选择支付方式',
                pay_type:'',
                pay_price:'',
                itemType:2,
                order_from:2,
                tag_list:[],
                remark:'',
                is_gift:'',
                gift_data:[{sku:'',gift_idName:'请选择赠品',num:'1',}],
                
            },
            ageIsVisible: false,
            ageoptions: [
                [{label:'50岁-60岁',value:'50岁-60岁',},
                {label:'40岁-50岁',value:'40岁-50岁',},
                {label:'30岁-40岁',value:'30岁-40岁',},
                {label:'30岁以下',value:'30岁以下',},
                {label:'60岁以上',value:'60岁以上',},]
            ],
            mobileVisible: false,
            searchform:{name:'',},
            defIndex: 1,
            goodsCouponList:[],
            numVisible: false,
            mainIndex:'',
            index:'',
            couponList:[],//优惠券
            couponIsVisible:false,
            payOptions:[
                [{label:'1',value:'全额支付',},
                {label:'0',value:'分期支付',},]
            ],
            payIsVisible:false,
            order_id:'',
            h5_pay_url:'',
            h5_pay_code:'',//订单二维码
            amountVisible:false,
            customKey: ['.'],
            totalAmount:'',
            totaltGoods:'',
            loadingport:false,
            submitport:false,
            selltagoptions:[],
            giftList:[],//赠品
            showPickerIndex:0,
            giftIsVisible:false,
        }
    },
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {
        var ua = navigator.userAgent.toLowerCase();
        this.isWeixin = ua.indexOf('micromessenger') != -1;
        // if(this.isWeixin){
        //     this.orderform.order_from=2;
        // }else{
        //     this.orderform.order_from=1;
        // }
        this.getcustomerDetail();
        this.getGoodsCouponListData();
        this.getselltagoptions();
        this.getgiftList();
    },
    computed: {
      ...mapState(['userInfo']),
    },
    // 生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {
        let name = this.$store.state.userInfo && this.$store.state.userInfo.name ? this.$store.state.userInfo.name : '请先登录';
        let phone = this.$store.state.userInfo && this.$store.state.userInfo.phone ? this.$store.state.userInfo.phone : '请先登录';
        this.$watermark.set(name +'-' + common.dateFormat('YYmmdd', new Date()));
    },
    // 监控data中的数据变化
    watch: {},
    // 方法集合
    methods: {
        navback(){
            this.$router.push({
                path:'/customerList'
            })
        },
        //选择年龄段
        openSwitchAge(param) {
            this[`${param}`] = true;
        },
        closeSwitchAge(param) {
            this[`${param}`] = false;
        },
        confirmAge(chooseData) {
            this.orderform.customer_ageName = `${chooseData[0].value}`;
            this.orderform.customer_age = `${chooseData[0].label}`;
            console.log(this.orderform.customer_age);
        },
        //输入手机号
        showKeyBoard() {
            this.mobileVisible = true;
        },
        closeMobile() {
            this.mobileVisible = false;
        },
        //搜索商品
        submit(){},
        //选项卡
        tabSwitch: function(index, event) {
            this.goodsCouponList=[];
            this.orderform.goods=[];
            this.orderform.itemType=index+1;
            console.log(index + '--' + event,this.orderform.itemType);
            this.getGoodsCouponListData();
        },
        //输入购买商品数量
        showKeyBoardNum(mainIndex,index) {
            this.numVisible = true;
            this.mainIndex=mainIndex;
            this.index=index;
        },
        showKeyBoardPackageNum(mainIndex,index) {
            this.numVisible = true;
            this.mainIndex=mainIndex;
            this.index=index;
        },
        inputNum(number) {
            // this.$toast.text(`输入：${number}`);
            if(this.orderform.itemType==1){
                //商品
                if(this.goodsCouponList[this.mainIndex].sku[this.index].num==0){
                    this.goodsCouponList[this.mainIndex].sku[this.index].num=number;
                }else{
                    this.goodsCouponList[this.mainIndex].sku[this.index].num=this.goodsCouponList[this.mainIndex].sku[this.index].num+String(number);
                }
                let totalPrice='';
                totalPrice=Number(this.goodsCouponList[this.mainIndex].sku[this.index].sales_price)*Number(this.goodsCouponList[this.mainIndex].sku[this.index].num);
                this.goodsCouponList[this.mainIndex].sku[this.index].totalPrice=totalPrice;
                
                let dataId = this.orderform.goods.findIndex((v) => {
                    return v.spu == this.goodsCouponList[this.mainIndex].spu;
                });
                if(dataId==-1){
                    this.orderform.goods.push(
                        {
                            spu:this.goodsCouponList[this.mainIndex].spu,
                            name:this.goodsCouponList[this.mainIndex].name,
                            sku:this.goodsCouponList[this.mainIndex].sku[this.index].sku,
                            num:this.goodsCouponList[this.mainIndex].sku[this.index].num,
                            attr:this.goodsCouponList[this.mainIndex].sku[this.index].attr,
                            totalPrice:this.goodsCouponList[this.mainIndex].sku[this.index].totalPrice,
                        }
                    );
                }else{
                    this.orderform.goods[dataId]={
                        spu:this.goodsCouponList[this.mainIndex].spu,
                        name:this.goodsCouponList[this.mainIndex].name,
                        sku:this.goodsCouponList[this.mainIndex].sku[this.index].sku,
                        num:this.goodsCouponList[this.mainIndex].sku[this.index].num,
                        attr:this.goodsCouponList[this.mainIndex].sku[this.index].attr,
                        totalPrice:this.goodsCouponList[this.mainIndex].sku[this.index].totalPrice,
                    };
                }
                console.log(this.orderform.goods)
            }else{
                //套餐
                if(this.goodsCouponList[this.mainIndex].num==0){
                    this.goodsCouponList[this.mainIndex].num=number;
                }else{
                    this.goodsCouponList[this.mainIndex].num=this.goodsCouponList[this.mainIndex].num+String(number);
                }
                let totalPrice='';
                totalPrice=Number(this.goodsCouponList[this.mainIndex].package_price)*Number(this.goodsCouponList[this.mainIndex].num);
                this.goodsCouponList[this.mainIndex].totalPrice=totalPrice;

                let dataId = this.orderform.goods.findIndex((v) => {
                    return v.id == this.goodsCouponList[this.mainIndex].id;
                });
                if(dataId==-1){
                    this.orderform.goods.push(
                        {
                            id:this.goodsCouponList[this.mainIndex].id,
                            name:this.goodsCouponList[this.mainIndex].package_name,
                            num:this.goodsCouponList[this.mainIndex].num,
                            attr:this.goodsCouponList[this.mainIndex].goods_package_sku,
                            totalPrice:this.goodsCouponList[this.mainIndex].totalPrice,
                        }
                    );
                }else{
                    this.orderform.goods[dataId]={
                        id:this.goodsCouponList[this.mainIndex].id,
                        name:this.goodsCouponList[this.mainIndex].package_name,
                        num:this.goodsCouponList[this.mainIndex].num,
                        attr:this.goodsCouponList[this.mainIndex].goods_package_sku,
                        totalPrice:this.goodsCouponList[this.mainIndex].totalPrice,
                    };
                }
                console.log(this.orderform.goods)
            }
            this.getCouponListData();
            this.getTotal();
        },
        deleteNum(){
            if(this.orderform.itemType==1){
                //商品
                let dataId = this.orderform.goods.findIndex((v) => {
                    return v.spu == this.goodsCouponList[this.mainIndex].spu;
                });
                console.log(this.goodsCouponList[this.mainIndex].sku[this.index].num.toString().length)
                if(this.goodsCouponList[this.mainIndex].sku[this.index].num.toString().length==1){
                    this.goodsCouponList[this.mainIndex].sku[this.index].num=0;

                    if(dataId!=-1){
                        this.orderform.goods.splice(dataId, 1);
                    }
                }else{
                    this.goodsCouponList[this.mainIndex].sku[this.index].num=
                    this.goodsCouponList[this.mainIndex].sku[this.index].num.substring(0, this.goodsCouponList[this.mainIndex].sku[this.index].num.length - 1);
                    
                    if(dataId!=-1){
                        this.orderform.goods[dataId]={
                            spu:this.goodsCouponList[this.mainIndex].spu,
                            name:this.goodsCouponList[this.mainIndex].name,
                            sku:this.goodsCouponList[this.mainIndex].sku[this.index].sku,
                            num:this.goodsCouponList[this.mainIndex].sku[this.index].num,
                            attr:this.goodsCouponList[this.mainIndex].sku[this.index].attr,
                            totalPrice:this.goodsCouponList[this.mainIndex].sku[this.index].totalPrice,
                        };
                    }
                }
                let totalPrice='';
                totalPrice=Number(this.goodsCouponList[this.mainIndex].sku[this.index].sales_price)*Number(this.goodsCouponList[this.mainIndex].sku[this.index].num);
                this.goodsCouponList[this.mainIndex].sku[this.index].totalPrice=totalPrice;
                console.log(this.orderform.goods)
            }else{
                //套餐
                let dataId = this.orderform.goods.findIndex((v) => {
                    return v.id == this.goodsCouponList[this.mainIndex].id;
                });
                console.log(this.goodsCouponList[this.mainIndex].num.toString().length)
                if(this.goodsCouponList[this.mainIndex].num.toString().length==1){
                    this.goodsCouponList[this.mainIndex].num=0;
                    if(dataId!=-1){
                        this.orderform.goods.splice(dataId, 1);
                    }
                }else{
                    this.goodsCouponList[this.mainIndex].num=
                    this.goodsCouponList[this.mainIndex].num.substring(0, this.goodsCouponList[this.mainIndex].num.length - 1);
                    if(dataId!=-1){
                        this.orderform.goods[dataId]={
                            id:this.goodsCouponList[this.mainIndex].id,
                            name:this.goodsCouponList[this.mainIndex].package_name,
                            num:this.goodsCouponList[this.mainIndex].num,
                            attr:this.goodsCouponList[this.mainIndex].goods_package_sku,
                            totalPrice:this.goodsCouponList[this.mainIndex].totalPrice,
                        };
                    }
                }
                let totalPrice='';
                totalPrice=Number(this.goodsCouponList[this.mainIndex].package_price)*Number(this.goodsCouponList[this.mainIndex].num);
                this.goodsCouponList[this.mainIndex].totalPrice=totalPrice;
                console.log(this.orderform.goods)
            }
            this.getCouponListData();
            this.getTotal();
        },
        closeNum() {
            this.numVisible = false;
        },
        submitGoodsData(){

        },
        //输入购买商品数量end
        //优惠券
        openSwitchCoupon(param) {
            this[`${param}`] = true;
        },
        closeSwitchCoupon(param) {
            this[`${param}`] = false;
        },
        confirmCoupon(chooseData) {
            this.orderform.coupon_idName = `${chooseData[0].value}`;
            this.orderform.coupon_id = `${chooseData[0].label}`;
            console.log(this.orderform.coupon_id);
            this.getTotal();
        },
        
        handleRadioChange(data){
            console.log(123)
        },
        openSwitchGift(param,index) {
            this[`${param}`] = true;
            this.showPickerIndex = index; // 设置当前选择器的索引
        },
        closeSwitchGift(param) {
            this[`${param}`] = false;
        },
        confirmGift(chooseData) {
            this.orderform.gift_data[this.showPickerIndex].gift_idName = `${chooseData[0].value}`;
            this.orderform.gift_data[this.showPickerIndex].sku = `${chooseData[0].label}`;
            console.log(this.orderform.gift_data[this.showPickerIndex].sku);
        },
        addGift(){
            console.log(this.orderform.gift_data);
           this.orderform.gift_data.push({sku:'',gift_idName:'请选择赠品',num:'1',});
        },
        delGift(index){
            this.orderform.gift_data.splice(index,1)
        },
        //支付方式
        openSwitchPay(param) {
            this[`${param}`] = true;
        },
        closeSwitchPay(param) {
            this[`${param}`] = false;
        },
        confirmPay(chooseData) {
            this.orderform.payTypeName = `${chooseData[0].value}`;
            this.orderform.pay_type = `${chooseData[0].label}`;
            console.log(this.orderform.pay_type);
            this.getTotal();
        },
        getTotal(){
            this.totalAmount=0;
            this.orderform.goods.forEach(item=>{
                console.log(item.totalPrice)
                if(item.totalPrice!=''){
                    this.totalAmount=this.totalAmount+Number(item.totalPrice)
                }
            });
            if(this.orderform.goods.length){
                this.totaltGoods='';
                this.orderform.goods.forEach(item=>{
                    if(item.num){
                        this.totaltGoods=Number(this.totaltGoods)+Number(item.num);
                    }
                })
            }
            console.log(this.totalAmount,this.totaltGoods)
        },
        showKeyBoardAmount(){
            this.amountVisible = true;
        },
        inputAmount(number) {
            // this.$toast.text(`输入：${number}`);
            this.orderform.pay_price=number;
        },
        closeAmount() {
            this.amountVisible = false;
        },
        //提交
        submitOrder(){
            if(!this.orderform.customer_disease_remark){
                this.$toast.fail('请输入客户症状！',{duration: 2000});
                return;
            }
            if(this.orderform.customer_age==''){
                this.$toast.fail('请选择客户年龄！',{duration: 2000});
                return;
            }
            if(this.orderform.pay_type==='0'){
                if(!this.orderform.pay_price){
                    this.$toast.fail('请输入支付金额！',{duration: 2000});
                    return;
                }
                if(this.orderform.pay_price < 0.01){
                    this.$toast.fail('支付金额不能小于0.01！',{duration: 2000});
                    return;
                }
            }
            if(!this.orderform.goods.length){
                this.$toast.fail('请选择商品！',{duration: 2000});
                return;
            }
            if(this.orderform.is_gift==1 && !this.orderform.gift_data[0].sku){
                this.$toast.fail('请选择赠品！',{duration: 2000});
                return;
            }
            if(this.orderform.pay_type===''){
                this.$toast.fail('请选择支付方式！',{duration: 2000});
                return;
            }
            if(this.orderform.consignee_change==1){
                this.orderform.consignee=this.orderform.consigneeCopy;
            }
            if(this.orderform.mobile_change==1){
                this.orderform.mobile=this.orderform.mobileCopy;
            }
            if(this.orderform.address_change==1){
                this.orderform.address=this.orderform.addressCopy;
            }
            let parmas = this.orderform;
            console.log(parmas);
            console.log(this.submitport);
            if (this.submitport) {
                return
            }
            this.submitport=true;
            createOrder(parmas).then(res => {
                if(res.data.code==200){
                    this.$toast.success("下单成功",{duration: 2000});
                    this.submitport=false;
                    // this.order_id=res.data.data.order_id;
                    // this.h5_pay_url=res.data.data.h5_pay_url;
                    // this.h5_pay_code=res.data.data.h5_pay_code;//订单二维码

                    this.$router.push({
                        path:'/orderDetails',
                        query:{
                            id:res.data.data.order_id
                        }
                    })
                }else{
                    this.submitport=false;
                    this.$toast.fail(res.data.msg,{duration: 2000});
                }
            });
        },

        //下单商品列表
        getGoodsCouponListData(){
            getGoodsCouponList({itemType:this.orderform.itemType,goods_name:'',page:1,pageNum:100}).then(res => {
                if(res.data.code==200){
                    this.goodsCouponList=[];
                    this.orderform.goods=[];
                    res.data.data.data.forEach(item=>{
                        if(this.orderform.itemType==1){
                            item.sku.forEach(sk=>{
                                sk.totalPrice='';
                                sk.num='0'
                            });
                        }
                        if(this.orderform.itemType==2){
                            item.totalPrice='';
                        }
                    });
                    this.goodsCouponList=res.data.data.data;
                }else{
                    this.$toast.fail(res.data.msg,{duration: 2000});
                }
            });
        },
        getgiftList(){
            goodsList({is_gift:1,status:2,page:1,pageNum:100}).then(res => {
                if(res.data.code==200){
                    console.log(res.data.data.data)
                    this.giftList[0]=[];
                    if(res.data.data.data){
                        res.data.data.data.forEach(item=>{
                            this.giftList[0].push({
                                id:item.sku,
                                label:item.sku,
                                value:item.name,
                            })
                        });
                    }
                }else{
                    this.$toast.fail( res.data.msg,{duration: 2000});
                }
            });
        },
        //优惠券
        getCouponListData(){
            getCouponList({item_type:this.orderform.itemType,goods:this.orderform.goods,}).then(res => {
                if(res.data.code==200){
                    console.log(res.data.data.data)
                    this.couponList[0]=[];
                    if(res.data.data){
                        res.data.data.forEach(item=>{
                            this.couponList[0].push({
                                label:item.id,
                                // value:item.coupon_describe
                                value:item.reduce_price,
                            })
                        });
                    }
                }else{
                    this.$toast.fail( res.data.msg,{duration: 2000});
                }
            });
        },
        //销售标签列表
        getselltagoptions(){
            selltagList({type:1,all:1,}).then(res => {
                if(res.data.code==200){
                    this.selltagoptions=[];
                    res.data.data.data.forEach(item=>{
                        this.selltagoptions.push({id:item.id,value:item.id,label:item.name},)
                    })
                }else{
                    this.$message({ duration: 2000,showClose: true,message: res.data.msg,type: 'error' });
                }
            })
        },
        //客户详情
        getcustomerDetail(){
            let params = {
                customerId:this.customerId
            }
            customerDetail(params).then(res => {
                if(res.data.code == 200){
                    this.customerDel=res.data.data;
                    this.orderform.consignee=res.data.data.desensitise_name;
                    this.orderform.consigneeCopy=res.data.data.desensitise_name;
                    this.orderform.mobile=res.data.data.desensitise_phone;
                    this.orderform.mobileCopy=res.data.data.desensitise_phone;
                    this.orderform.province=res.data.data.province;
                    this.orderform.city=res.data.data.city;
                    this.orderform.district=res.data.data.area;
                    this.orderform.address=res.data.data.desensitise_street;
                    this.orderform.addressCopy=res.data.data.desensitise_street;
                }else{
                    this.$toast.fail(res.data.msg,{duration: 2000});
                }
            })
        },
        copyUrl(){
            let that=this;
            this.$copyText(this.h5_pay_url).then(function(){
                that.$toast.success("支付链接复制到剪切板",{duration: 2000});
            }, function(e) {

            });
        },
        downloadImg(){
            var ua = navigator.userAgent.toLowerCase();
            var isWeixin = ua.indexOf('micromessenger') != -1;
            if(isWeixin){
                this.$toast.success("请长按图片保存",{duration: 2000});
            }else{
                let a = document.createElement('a');
                a.style.display = 'none';
                a.href = this.h5_pay_code;
                a.setAttribute('download', '支付二维码.jpg')
                document.body.appendChild(a);
                a.click(); //执行下载
                window.URL.revokeObjectURL(a.href);
                document.body.removeChild(a);
            }
        },
        //获取订单支付状态
        getOrderStatusData(order_id){
            getOrderStatus({order_id:order_id}).then(res => {
                if(res.data.code==200){
                    if(res.data.data.status==1){
                        //已支付
                        this.orderdialog=false;
                        clearInterval(statusTime)
                        this.$toast.success("支付成功",{duration: 2000});
                    }
                }else{
                    this.$toast.fail( res.data.msg,{duration: 2000});
                }
            });
        },
        //客户信息解密
        async showConsignee(data){
            if(this.orderform.consignee_change==2){
                let returnData=await this.getcustomerView(this.customerId,1,3,'');
                this.orderform.consigneeCopy=returnData;
                this.orderform.consignee_change=returnData?1:2;
            }else{
                // this.orderform.consigneeCopy=this.orderform.consignee;
                // this.orderform.consignee_change=2;
            }
        },
        async showPhone(data){
            if(this.orderform.mobile_change==2){
                let returnData=await this.getcustomerView(this.customerId,2,3,'');
                this.orderform.mobileCopy=returnData;
                this.orderform.mobile_change=returnData?1:2;
            }else{
                // this.orderform.mobileCopy=this.orderform.mobile;
                // this.orderform.mobile_change=2;
            }
        },
        async showStreet(data){
            if(this.orderform.address_change==2){
                let returnData=await this.getcustomerView(this.customerId,3,3,'');
                this.orderform.addressCopy=returnData;
                this.orderform.address_change=returnData?1:2;
            }else{
                // this.orderform.addressCopy=this.orderform.address;
                // this.orderform.address_change=2;
            }
        },
        copyText(phone){
            let that=this;
            this.$copyText(phone).then(function(){
                that.$toast.success("内容已复制到剪切板!",{duration: 2000});
            }, function(e) {

            });
        },
        async getcustomerView(customer_id,type,source,oid){
            if (this.loadingport) {
                return
            }
            this.loadingport=true;
            let decryptData='';
            let parmas = {customer_id,type,source,oid,};
            await customerView(parmas).then(res => {
                if(res.data.code==200){
                    decryptData=res.data.data.decryptData;
                    this.loadingport=false;
                }else{
                    this.$toast.fail( res.data.msg,{duration: 2000});
                    this.loadingport=false;
                }
            });
            return decryptData
        },
        getcustomerPhoneView(customer_id,type,source,oid){
            if (this.loadingport) {
                return
            }
            this.loadingport=true;
            let decryptData='';
            let parmas = {customer_id,type,source,oid,};
            customerPhoneView(parmas).then(res => {
                if(res.data.code==200){
                    decryptData=res.data.data.decryptData;
                    window.location.href = "tel://" +decryptData;
                    this.loadingport=false;
                }else{
                    this.$toast.fail( res.data.msg,{duration: 2000});
                    this.loadingport=false;
                }
            });
        },
        //客户信息解密end
    }
}
</script>
<style>
    .nut-icon svg{
        margin-left:0;
    }
    .giftNum{
        display:flex;
        justify-content:space-between;
        margin-top:10px;
    }
    .creatOrder {
        height:100%;
        background: #fff;
    }
    .creatOrder .nut-textinput-label{
        width:0.6rem;
    }
    .creatOrder .nut-cell{
        padding: 0;
    }
    .creatOrder .nut-cell-desc{
        color:#2d2d2d;
        font-size: 14px;
    }
    .autoInput{
        display: flex;
        width:100%;
        align-items: center;    
        border-bottom: 1px solid rgba(218, 218, 218, 0.5);
    }
    .autoFucus {
        background: #fff;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width:calc(100% - 20px);
    }
    .autoFucus .my-input {
        flex: 1
    }
    .autoFucus .searchBtn {
        margin-left: 10px
    }
    .searchBtn{
        font-size: 14px;
    }
    .p_title{
        font-size: 14px;
        padding:10px;
    }
    .boxflex{
        display: flex;
        font-size: 14px;
        border:1px solid #cdcdcd;
        margin:10px 0;
        padding:10px;
        align-items:center;
    }
    .item{
        flex-grow: 2;
        padding-right:8px;
    }
    .item div p{
        margin-top:10px;
    }
    .nut-tab-item{
        height:auto !important;
    }
    .divNum {
        width:100px;
    }
    .divNum p{
        margin-top:10px;
    }
    .divNum .nut-textinput input{
        width:100%;
        height: 30px;
    }
    .nut-radio {
        margin-right: 10px;
        height: 36px;
        display: inline-block;
        line-height: 36px;
    }

    .flexDiv{
        display: flex;
        margin-bottom: 6px;
    }
</style>