<template>
    <div style="height:100%;background:#fff;">
        <nut-navbar 
            style="background-color:#545456; color: #fff;"
            @on-click-back="navback" 
            :leftShow="true" 
            :rightShow="false"
        >客户信息</nut-navbar>
        <nut-buttongroup>
            <nut-button @click="callPhone()" type="light">打电话</nut-button>
            <nut-button @click="getCreatedOrder()">下单</nut-button>
        </nut-buttongroup>
        <nut-collapse v-model="activeNames" @change="callback">
            <nut-collapse-item title="客户信息" :name="1">
                <div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">姓名</p>
                        <p @click="showName()" style="flex: 2;text-align:right;">
                            {{nameCopy}}
                            <i :class="!nameChange?'iconfont icon-biyanjing':'iconfont icon-yanjing'" style="color:#2bc58f;margin-left:0.06rem;"></i>
                        </p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">电话</p>
                        <p @click="showPhone()" style="flex: 2;text-align:right;">
                            {{phoneCopy}}
                            <i :class="!phoneChange?'iconfont icon-biyanjing':'iconfont icon-yanjing'" style="color:#2bc58f;margin-left:0.06rem;"></i>
                        </p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">性别</p>
                        <p style="flex: 2;text-align:right;">{{customerDel.gender==1?'男':customerDel.gender==2?'女':'未知'}}</p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">地域</p>
                        <p style="flex: 2;text-align:right;">{{customerDel.province}}{{customerDel.city}}</p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">区县</p>
                        <p style="flex: 2;text-align:right;">{{customerDel.area}}</p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">地址</p>
                        <p v-if="streetCopy" @click="showStreet()" style="flex: 2;text-align:right;">
                            {{streetCopy}}
                            <i :class="!streetChange?'iconfont icon-biyanjing':'iconfont icon-yanjing'" style="color:#2bc58f;margin-left:0.06rem;"></i>
                        </p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">来源</p>
                        <p style="flex: 2;text-align:right;">{{customerDel.origin}}</p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">最后入公海时间</p>
                        <p style="flex: 2;text-align:right;">{{customerDel.sea_date}}</p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">客户分配时间</p>
                        <p style="flex: 2;text-align:right;">{{customerDel.bind_date}}</p>
                    </div>
                </div>
            </nut-collapse-item>
            <nut-collapse-item title="原始数据" :name="2">
                <div v-for="(item,index) in orderTableData" :key="index" style="border-bottom:1px solid #ccc;padding-bottom:6px;padding-top:10px;">
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">订单ID</p>
                        <p style="flex: 2;text-align:right;">{{item.oid}}</p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">平台</p>
                        <p style="flex: 2;text-align:right;">{{item.type_detail}}</p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">金额</p>
                        <p style="flex: 2;text-align:right;">{{item.totalFee}}</p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">收货人</p>
                        <p @click="showNameOrderData(item,1)" style="flex: 2;text-align:right;">
                            {{item.consigneeCopy}}
                            <i :class="!item.changeConsignee?'iconfont icon-biyanjing':'iconfont icon-yanjing'" style="color:#2bc58f;margin-left:0.06rem;"></i>
                        </p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">手机号</p>
                        <p @click="showPhoneOrderData(item,1)" style="flex: 2;text-align:right;">
                            {{item.mobileCopy}}
                            <i :class="!item.changeMobile?'iconfont icon-biyanjing':'iconfont icon-yanjing'" style="color:#2bc58f;margin-left:0.06rem;"></i>                        
                        </p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">订单状态</p>
                        <p style="flex: 2;text-align:right;">
                            <span v-if="item.status_enum==10" class="payE">{{item.status}}</span>
                            <span v-else-if="item.status_enum==30" class="payS">{{item.status}}</span>
                            <span v-else-if="item.status_enum==40" class="sendE">{{item.status}}</span>
                            <span v-else-if="item.status_enum==50" class="sendS">{{item.status}}</span>
                            <span v-else-if="item.status_enum==70" class="orderS">{{item.status}}</span>
                            <span v-else-if="item.status_enum==80" class="orderE">{{item.status}}</span>
                            <span v-else class="">{{item.status}}</span>
                        </p>
                    </div>
                    <div v-if="item.order_to_goods.length">
                        <div v-for="(good,googindex) in item.order_to_goods" :key="googindex" class="flexDiv">
                            <p style="color:#2d2d2d;">商品</p>
                            <div style="flex: 2;text-align:right;">
                                <p>{{good.goods_desc}}</p>
                                <p style="margin-bottom:0;">规格：{{good.sku_desc}}</p>
                                <p style="margin-bottom:0;">数量：{{good.amount}}</p>
                                <p style="margin-bottom:0;">价格：{{good.price}}</p>
                            </div>
                        </div>
                    </div>
                    <div v-if="item.itemTitle">
                        <div class="flexDiv">
                            <p style="color:#2d2d2d;">商品</p>
                            <div style="flex: 2;text-align:right;">
                                <p>{{item.payTime}}</p>
                                <p>规格：{{item.skuDesc}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">支付时间</p>
                        <p style="flex: 2;text-align:right;">{{item.payTime}}</p>
                    </div>
                    <!-- <div class="flexDiv">
                        <p style="color:#2d2d2d;">来源</p>
                        <p style="flex: 2;text-align:right;">{{item.source}}</p>
                    </div> -->
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">归属人</p>
                        <p style="flex: 2;text-align:right;">{{item.admin_name}}</p>
                    </div>
                </div>
            </nut-collapse-item>
            <nut-collapse-item title="购买记录" :name="3">
                <div v-for="(item,index) in tableData" :key="index" style="border-bottom:1px solid #ccc;padding-bottom:6px;padding-top:10px;">
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">订单号</p>
                        <p style="flex: 2;text-align:right;">{{item.oid}}</p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">数量</p>
                        <p style="flex: 2;text-align:right;">{{item.num}}</p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">支付金额</p>
                        <p style="flex: 2;text-align:right;">{{item.payFee}}</p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">优惠金额</p>
                        <p style="flex: 2;text-align:right;">{{item.discountFee}}</p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">收货人</p>
                        <p @click="showNameOrderData(item,2)" style="flex: 2;text-align:right;">
                            {{item.consigneeCopy}}
                            <i :class="!item.changeConsignee?'iconfont icon-biyanjing':'iconfont icon-yanjing'" style="color:#2bc58f;margin-left:0.06rem;"></i>
                        </p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">手机号</p>
                        <p @click="showPhoneOrderData(item,1)" style="flex: 2;text-align:right;">
                            {{item.mobileCopy}}
                            <i :class="!item.changeMobile?'iconfont icon-biyanjing':'iconfont icon-yanjing'" style="color:#2bc58f;margin-left:0.06rem;"></i>                        
                        </p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">订单状态</p>
                        <p style="flex: 2;text-align:right;">
                            <!-- <span v-if="item.status_enum==10" class="payE">{{item.status}}</span>
                            <span v-else-if="item.status_enum==30" class="payS">{{item.status}}</span>
                            <span v-else-if="item.status_enum==40" class="sendE">{{item.status}}</span>
                            <span v-else-if="item.status_enum==50" class="sendS">{{item.status}}</span>
                            <span v-else-if="item.status_enum==70" class="orderS">{{item.status}}</span>
                            <span v-else-if="item.status_enum==80" class="orderE">{{item.status}}</span>
                            <span v-else class="">{{item.status}}</span> -->
                            <span>{{item.status_detail}}</span>
                        </p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">客户症状</p>
                        <p style="flex: 2;text-align:right;">{{item.customer_disease_remark}}</p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">年龄段</p>
                        <p style="flex: 2;text-align:right;">{{item.customer_age}}</p>
                    </div>
                </div>
            </nut-collapse-item>
            <nut-collapse-item title="销售记录" :name="4">
                <div v-for="(item,index) in activities" :key="index" style="border-bottom:1px solid #ccc;padding-bottom:6px;padding-top:10px;">
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">时间</p>
                        <p style="flex: 2;text-align:right;">{{item.created_at}}</p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">类型</p>
                        <p style="flex: 2;text-align:right;">{{item.type}}</p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">内容</p>
                        <p style="flex: 2;text-align:right;">{{item.remarks}}</p>
                    </div>
                    <div class="flexDiv">
                        <p style="color:#2d2d2d;">操作人</p>
                        <p style="flex: 2;text-align:right;">{{item.admin_name}}</p>
                    </div>
                </div>
            </nut-collapse-item>
        </nut-collapse>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { customerDetail,orderList,getMyOrder,customerHandleLog,customerView,customerPhoneView } from '@/api/api.js'
import common from '../common/js/util.js';
export default {
    components: {
    },
    data(){
        return {
            activeNames: [1],
            customerId:this.$route.query.id,
            nameCopy:'',
            nameChange:false,
            phoneCopy:'',
            phoneChange:false,
            streetCopy:'',
            streetChange:false,
            customerDel:{},
            orderTableData:[],
            tableData:[],
            activities:[],
            loadingport:false,
        }
    },
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {
        this.getcustomerDetail();
        this.getorderList();
        this.getMyOrderList();
        this.getcustomerHandleLog();
    },
    computed: {
      ...mapState(['userInfo']),
    },
    // 生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {
        let name = this.$store.state.userInfo && this.$store.state.userInfo.name ? this.$store.state.userInfo.name : '请先登录';
        let phone = this.$store.state.userInfo && this.$store.state.userInfo.phone ? this.$store.state.userInfo.phone : '请先登录';
        this.$watermark.set(name +'-' + common.dateFormat('YYmmdd', new Date()));
    },
    // 监控data中的数据变化
    watch: {},
    // 方法集合
    methods: {
        navback(){
            this.$router.push({
                path:'/customerList'
            })
        },
        callback(name) {
            // console.log(activeNames, `点击了name是${name}的面板,callback`);
        },

        async callPhone(phoneNumber) {
            // console.log(await this.getcustomerPhoneView(this.customerDel.id,2,3,''));
            this.getcustomerPhoneView(this.customerDel.id,2,3,'');
        },
        getcustomerDetail(){
            let params = {
                customerId:this.customerId
            }
            customerDetail(params).then(res => {
                if(res.data.code == 200){
                    // this.$toast.success("登陆成功",{duration: 2000});
                    this.customerDel=res.data.data;
                    this.nameCopy=res.data.data.desensitise_name;
                    this.phoneCopy=res.data.data.desensitise_phone;
                    this.streetCopy=res.data.data.desensitise_street;
                }else{
                    this.$toast.fail(res.data.msg,{duration: 2000});
                }
            })
        },

        //客户原订单记录
        getorderList(){
            orderList({customer_id:this.customerId,}).then(res => {
                if(res.data.code==200){
                    res.data.data.data.forEach(item => {
                        item.consigneeCopy=item.desensitise_consignee;
                        item.changeConsignee=false;
                        item.mobileCopy=item.desensitise_mobile;
                        item.changeMobile=false;
                        item.addressCopy=item.desensitise_address;
                        item.changeAddress=false;
                    });
                    this.orderTableData=res.data.data.data;
                }else{
                    this.$toast.fail(res.data.msg,{duration: 2000});
                }
            })
        },
        getMyOrderList(){
            getMyOrder({customer_id:this.customerId,}).then(res => {
                if(res.data.code==200){
                    res.data.data.data.forEach(item => {
                        item.consigneeCopy=item.desensitise_consignee;
                        item.changeConsignee=false;
                        item.mobileCopy=item.desensitise_mobile;
                        item.changeMobile=false;
                        item.addressCopy=item.desensitise_address;
                        item.changeAddress=false;
                    });
                    this.tableData=res.data.data.data;
                }else{
                    this.$toast.fail(res.data.msg,{duration: 2000});
                }
            })
        },
        //销售记录
        getcustomerHandleLog(){
            customerHandleLog({customerId:this.customerId}).then(res => {
                if(res.data.code==200){
                    this.activities=res.data.data.data;
                }else{
                    this.$toast.fail(res.data.msg,{duration: 2000});
                }
            });
        },
        getCreatedOrder(){
            this.$router.push({
                path:'/createdOrder',
                query:{
                    id:this.customerId
                }
            })
        },

        //客户信息解密
        async showName(data){
            if(!this.nameChange){
                let returnData=await this.getcustomerView(this.customerDel.id,1,3,'');
                this.nameCopy=returnData;
                this.nameChange=returnData?true:false;
            }else{
                this.nameCopy=this.customerDel.desensitise_name;
                this.nameChange=false;
            }
        },
        async showPhone(data){
            if(!this.phoneChange){
                let returnData=await this.getcustomerView(this.customerDel.id,2,3,'');
                this.phoneCopy=returnData;
                this.phoneChange=returnData?true:false;
            }else{
                this.phoneCopy=this.customerDel.desensitise_phone;
                this.phoneChange=false;
            }
        },
        async showStreet(data){
            if(!this.streetChange){
                let returnData=await this.getcustomerView(this.customerDel.id,3,3,'');
                this.streetCopy=returnData;
                this.streetChange=returnData?true:false;
            }else{
                this.streetCopy=this.customerDel.desensitise_street;
                this.streetChange=false;
            }
        },
        async showNameOrderData(data,source){
            if(!data.changeConsignee){
                let returnData=await this.getcustomerView(data.customer_id,1,source,data.oid);
                data.consigneeCopy=returnData;
                data.changeConsignee=returnData?true:false;
            }else{
                data.consigneeCopy=data.desensitise_consignee;
                data.changeConsignee=false;
            }
        },
        async showPhoneOrderData(data,source){
            if(!data.changeMobile){
                let returnData=await this.getcustomerView(data.customer_id,2,source,data.oid);
                data.mobileCopy=returnData;
                data.changeMobile=returnData?true:false;
            }else{
                data.mobileCopy=data.desensitise_mobile;
                data.changeMobile=false;
            }
        },
        copyText(phone){
            let that=this;
            this.$copyText(phone).then(function(){
                that.$toast.success("内容已复制到剪切板!",{duration: 2000});
            }, function(e) {

            });
        },
        async getcustomerView(customer_id,type,source,oid){
            if (this.loadingport) {
                return
            }
            this.loadingport=true;
            let decryptData='';
            let parmas = {customer_id,type,source,oid,};
            await customerView(parmas).then(res => {
                if(res.data.code==200){
                    decryptData=res.data.data.decryptData;
                    this.loadingport=false;
                }else{
                    this.$toast.fail(res.data.msg,{duration: 2000});
                    this.loadingport=false;
                }
            });
            return decryptData
        },
        getcustomerPhoneView(customer_id,type,source,oid){
            if (this.loadingport) {
                return
            }
            this.loadingport=true;
            let decryptData='';
            let parmas = {customer_id,type,source,oid,};
            customerPhoneView(parmas).then(res => {
                if(res.data.code==200){
                    decryptData=res.data.data.decryptData;
                    window.location.href = "tel://" +decryptData;
                    this.loadingport=false;
                }else{
                    this.$toast.fail(res.data.msg,{duration: 2000});
                    this.loadingport=false;
                }
            });
        },
        //客户信息解密end
    }
}
</script>
<style scoped>
.flexDiv{
    display: flex;
    margin-bottom: 6px;
}
.payE{
  color:#f56c6c;
}
.payS{
  color:#67c23a;
}
.sendE{
  color:#e6a23c;
}
.sendS{
  color:#fac768;
}
.orderS{
  color:#409eff;
}
.orderE{
  color:#f56c6c;
}
</style>
<style>
.nut-navbar .nav-center .nav-title {
    font-size: 14px;
}
</style>