import Vue from 'vue'
import App from './App.vue'
import "../src/assets/style/reset.css"
import router from './router/index'
import store from './vuex/store';
Vue.prototype.$store = store;
import Vuex from 'vuex';

// import MintUI from 'mint-ui'
// import 'mint-ui/lib/style.css'
// import{ Toast }from"mint-ui";
// Vue.prototype.$toast = Toast;

import NutUI from '@nutui/nutui';
import '@nutui/nutui/dist/nutui.css';

import Vant from 'vant';
import 'vant/lib/index.css';

import VueClipboard from 'vue-clipboard2';
import './assets/iconFont/iconfont.css'

import watermark from '@/assets/watermark.js';
Vue.prototype.$watermark = watermark;
Vue.use(VueClipboard);
Vue.config.productionTip = false;
Vue.use(Vuex);
// Vue.use(MintUI)
Vue.use(NutUI)
Vue.use(Vant);
let $vue = new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

export default $vue
